import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import { btnTheme } from 'configs/theme';
import AdjustIcon from 'assets/icons/button-setting.png';
import { BasicPopover } from 'Components/Popover';
import { BasicCheckbox, handleCheck } from 'Components/Checkbox';
import { AdjustBtnProps } from 'types/Button';
import ResetButton from '../ResetButton';

const Component: React.FC<AdjustBtnProps> = ({ header, list, selected, btn }) => {
  const anchorRef = React.useRef(null);
  const [adjustOpen, setadjustOpen] = React.useState(false);

  return (
    <ThemeProvider theme={btnTheme}>
      <Button
        variant='outlined'
        color='inherit'
        className='gap-2 cursor-pointer'
        onClick={() => setadjustOpen(true)}
        ref={anchorRef}
      >
        <img src={AdjustIcon} alt='export' className='w-4 rotate-90' />
        <span>adjust</span>
      </Button>

      <BasicPopover open={adjustOpen} anchorEl={anchorRef.current} onClose={() => setadjustOpen(false)}>
        <div className='text-[#94A3B8] px-4 py-2'>Adjust display data:</div>
        {header.map((head, headIdx) => (
          <button
            key={headIdx}
            onClick={() => selected?.set(head.label)}
            className={`${
              head.label === selected?.val ? 'text-[#21AF7D] bg-[#F6F6F6]' : ''
            } px-4 text-start font-bold flex capitalize`}
          >
            <BasicCheckbox
              value={head.label}
              checked={list?.val.includes(head.label) ? true : false}
              onChange={(e) => handleCheck(e, list?.val, list?.set)}
            />
            <p className='my-auto'>{head.label}</p>
          </button>
        ))}
        <ResetButton onClick={btn?.reset} />
      </BasicPopover>
    </ThemeProvider>
  );
};

export default Component;
