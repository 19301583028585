import { AxiosError } from 'axios';

export enum IMercPreviewTypes {
  GET_BIZ_SUCCESS = 'GET_BIZ_SUCCESS',
  GET_METODE_PEMBAYARAN_SUCCESS = 'GET_METODE_PEMBAYARAN_SUCCESS',

  GET_MERCHANT_PENDING = 'GET_MERCHANT_PENDING',
  GET_MERCHANT_FAILED = 'GET_MERCHANT_FAILED',
}

// State's
export interface IMercPreviewState {
  bizType: BizTypeData[];
  paymentMethod: PaymentMethodData[];
  isLoading: boolean;
  error: AxiosError;
}

// Data's
export interface PaymentMethodData {
  id: number;
  name: string;
  pict_url: string;
  Channels?: ChannelData[];
}
interface ChannelData {
  id: number;
  code: string;
  name: string;
  pict_url: string;
}

export interface BizTypeData {
  categories: BizCategoryData[];
  code: string;
  id: number;
  name: string;
  status: number;
}
interface BizCategoryData {
  code: string;
  id: number;
  name: string;
  status: number;
  type: string;
}

// Actions
interface GetBisnisSuccess {
  type: IMercPreviewTypes.GET_BIZ_SUCCESS;
  payload: BizTypeData[];
}
interface GetMetodePembayaranSuccess {
  type: IMercPreviewTypes.GET_METODE_PEMBAYARAN_SUCCESS;
  payload: PaymentMethodData[];
}

interface GetMerchantPreviewPending {
  type: IMercPreviewTypes.GET_MERCHANT_PENDING;
  payload: null;
}
interface GetMerchantPreviewFailed {
  type: IMercPreviewTypes.GET_MERCHANT_FAILED;
  payload: { error: AxiosError };
}

export type IMercPreview =
  | GetBisnisSuccess
  | GetMetodePembayaranSuccess
  | GetMerchantPreviewPending
  | GetMerchantPreviewFailed;
