import { AxiosError } from 'axios';

export enum SettlementTypes {
  GET_REFUND_STATUS = 'GET_REFUND_STATUS',
  GET_REFUND_STATUS_DETAIL = 'GET_REFUND_STATUS_DETAIL',
  GET_REFUND_REPORT = 'GET_REFUND_REPORT',

  SET_BANK_STATEMENT = 'SET_BANK_STATEMENT',
  SET_SETTLEMENT_DATA = 'SET_SETTLEMENT_DATA',
  SET_SETTLEMENT_RECONSILE = 'SET_SETTLEMENT_RECONSILE',
  SET_THIRD_PARTY = 'SET_THIRD_PARTY',

  GET_SETTLEMENT_START = 'GET_SETTLEMENT_START',
  GET_SETTLEMENT_SUCCESS = 'GET_SETTLEMENT_SUCCESS',
  GET_SETTLEMENT_DETAIL = 'GET_SETTLEMENT_DETAIL',
  GET_SETTLEMENT_FAILED = 'GET_SETTLEMENT_FAILED',

  GET_PARTNER_START = 'GET_PARTNER_START',
  GET_PARTNER_LIST = 'GET_PARTNER_LIST',
  GET_PARTNER_FAILED = 'GET_PARTNER_FAILED',

  GET_PAYMENT_START = 'GET_PAYMENT_START',
  GET_PAYMENT_LIST = 'GET_PAYMENT_LIST',
  GET_PAYMENT_FAILED = 'GET_PAYMENT_FAILED',
}

// State
export interface ISettlementState {
  third_party: string;
  id: string;
  is_match: string;
  enabled: { settlement: boolean; bank: boolean };
  settlement_data: SettlementDatas[];
  settlement_detail: SettlementDetailDatas[];
  detail_header: DetailHeader;
  bank_statement: any[];
  date: string;
  pagination: IPagination;
  partner: PartnerData[];
  isLoading: boolean;
  error: AxiosError;
  masterPayment: any;
}
interface IPagination {
  current_page: number;
  records: number;
  total_page: number;
}
interface SettlementDetailState {
  detail: SettlementDetailDatas[];
  header?: DetailHeader;
  pagination?: IPagination;
}

// Data
interface ReconsileData {
  id: string;
  is_match: string;
  enabled: {
    settlement: boolean;
    bank: boolean;
  };
  date: string;
}

interface ListSettlementData {
  data: SettlementDatas[];
  message?: string;
  pagination: {
    current_page: number;
    records: number;
    total_page: number;
  };
  status: string;
  status_code: number;
}

export interface SettlementDatas {
  batch_id: number;
  file_name: string;
  partner_id: number;
  partner_name: string;
  payment_method_name: string;
  total_row: number;
  upload_by: number;
  upload_by_name: string;
  upload_date: string;
}

interface SettlementDetailDatas {
  amount: number;
  batch_id: number;
  data: string;
  detail_id: number;
  reference_no: string;
  status: number;
  status_name: string;
  transaction_id: string;
  transaction_no: string;
  update_status_date: string;
}

interface DetailHeader {
  batch_id?: number;
  file_name?: string;
  partner_id?: number;
  total_row?: number;
  upload_by?: number;
  upload_date?: string;
}

export interface DetailStatusData {
  records: number;
  status: number;
  status_name: string;
}

interface PartnerData {
  code?: string;
  id?: number;
  name?: string;
  pm_id?: number;
  status?: number;
  type?: number;
}

// Action
interface SetThirdParty {
  type: SettlementTypes.SET_THIRD_PARTY;
  payload: string;
}
interface SetSettlementData {
  type: SettlementTypes.SET_SETTLEMENT_DATA;
  payload: any[]; // sementara
}
interface SetBankStatement {
  type: SettlementTypes.SET_BANK_STATEMENT;
  payload: any[]; //sementara
}
interface SetSettlementReconsile {
  type: SettlementTypes.SET_SETTLEMENT_RECONSILE;
  payload: ReconsileData;
}
interface GetSettlementStart {
  type: SettlementTypes.GET_SETTLEMENT_START;
  payload: null;
}
interface GetSettlementSuccess {
  type: SettlementTypes.GET_SETTLEMENT_SUCCESS;
  payload: { data: SettlementDatas[]; pagination: IPagination };
}
interface GetSettlementFailed {
  type: SettlementTypes.GET_SETTLEMENT_FAILED;
  payload: { error: AxiosError };
}
interface GetSettlementDetail {
  type: SettlementTypes.GET_SETTLEMENT_DETAIL;
  payload: SettlementDetailState;
}
interface GetPartnerStart {
  type: SettlementTypes.GET_PARTNER_START;
  payload: null;
}
interface GetPartnerSuccess {
  type: SettlementTypes.GET_PARTNER_LIST;
  payload: PartnerData[];
}
interface GetPartnerFailed {
  type: SettlementTypes.GET_PARTNER_FAILED;
  payload: { error: AxiosError };
}
interface GetPaymentStart {
  type: SettlementTypes.GET_PAYMENT_START;
  payload: null;
}
interface GetPaymentSuccess {
  type: SettlementTypes.GET_PAYMENT_LIST;
  payload: PartnerData[];
}
interface GetPaymentFail {
  type: SettlementTypes.GET_PAYMENT_FAILED;
  payload: { error: AxiosError };
}

type IPartner = GetPartnerFailed | GetPartnerStart | GetPartnerSuccess;

type IPayment = GetPaymentStart | GetPaymentSuccess | GetPaymentFail;

export type ISettlement =
  | SetThirdParty
  | SetSettlementData
  | SetBankStatement
  | SetSettlementReconsile
  | GetSettlementStart
  | GetSettlementSuccess
  | GetSettlementFailed
  | GetSettlementDetail
  | IPartner
  | IPayment;
