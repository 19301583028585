import React from 'react';
import { createTheme, FormHelperText, OutlinedInput, OutlinedInputProps, ThemeProvider } from '@mui/material';
import { icons } from 'assets';

const TextProps = createTheme({
  typography: { fontFamily: 'Manrope' },
});

type ICustomTextField = OutlinedInputProps & {
  errortext?: string;
  // adds custom props
};

const Component: React.FC<ICustomTextField> = (props) => {
  const { disabled = false } = props;
  return (
    <ThemeProvider theme={TextProps}>
      <OutlinedInput disabled={disabled} size='small' fullWidth sx={{ bgcolor: disabled && '#F7F7F7' }} {...props} />
      {props?.error && (
        <FormHelperText error className='flex gap-2' sx={{ mt: 1 }}>
          <img src={icons.infoError} alt='err' />
          <span className='my-auto text-base'>{props.errortext}</span>
        </FormHelperText>
      )}
    </ThemeProvider>
  );
};
export default Component;
