import { AxiosError } from 'axios';
import { ActivityData } from 'hooks/useActivityBrowser';

export enum AuthTypes {
  USER_LOGIN_PENDING = 'USER_LOGIN_PENDING',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR = 'USER_LOGIN_ERROR',
  USER_LOGOUT = 'USER_LOGOUT',

  VALIDATE_EMAIL = 'VALIDATE_EMAIL',

  GET_PROFILE_PENDING = 'GET_PROFILE_PENDING',
  GET_PROFILE_ERROR = 'GET_PROFILE_ERROR',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO',
}

// STATES
export interface IAuthState {
  isLoading?: boolean;
  userData: IUserData;
  profile: Partial<IProfileData>;
  error?: AxiosError;
}

// DATA's
export interface IUserData {
  ClientId?: number;
  DeviceId?: null;
  Email?: string;
  EmailConfirmed?: boolean;
  Env?: boolean;
  FirstAlertPasswordExpiryDay?: number;
  Fullname?: string;
  Id?: number;
  InstallId?: null;
  MerchantId?: number;
  PackageType?: null | number;
  PassExpired?: boolean;
  PassExpiredDate?: string;
  Phone?: string;
  PhoneConfirmed?: boolean;
  SecondAlertPasswordExpiryDay?: number;
  Status?: string;
  ThirdAlertPasswordExpiryDay?: number;
  Token?: string;
  Username?: string;
}

export interface IProfileData {
  ClientId: number;
  Email: string;
  EmailConfirmed: boolean;
  Fullname: string;
  Id: number;
  MerchantStatus: string;
  Phone: string;
  PhoneConfirmed: boolean;
  ProfileImage: null;
  RoleName: string;
  Status: string;
  Username: string;

  PassExpiredDate: string;
  FirstAlertPasswordExpiryDay: number;
  PassExpired: boolean;
  SecondAlertPasswordExpiryDay: number;
  ThirdAlertPasswordExpiryDay: number;
}

export interface JWTdata {
  ClientId: number;
  Expired: string;
  IsAdmin: false;
  MemberId: number;
  MerchantCode: string;
  MerchantId: number;
  PackageType: number;
  Permission: string[];
  Source: string;
  UserId: number;
}

type roles = { roles: string[] };
export interface JWTKeycloak {
  'exp': number;
  'iat': number;
  'auth_time': number;
  'jti': string;
  'iss': string;
  'aud': string[];
  'sub': string;
  'typ': string;
  'azp': string;
  'session_state': string;
  'acr': string;
  'allowed-origins': string[];
  'realm_access': roles;
  'resource_access': {
    'realm-management': roles;
    'account': roles;
  };
  'scope': string;
  'sid': string;
  'email_verified': boolean;
  'groups': string[];
  'preferred_username': string;
  'MerchantId': number;
  'given_name': string;
  'Source': string;
  'MemberId': number;
  'MerchantCode': string;
  'UserId': number;
  'PackageType': number;
  'name': string;
  'ClientId': number;
  'family_name': string;
  'email': string;
}

export type PForgot = Partial<ActivityData> & { email: string };
export type PLogin = Partial<ActivityData> & PForgot & { password: string };
export type PPassword = Partial<ActivityData> & { password: string; confirm: string };
export type PEmailValid = Partial<ActivityData> & { key: string };
export type PForgotValid = Partial<ActivityData> & PPassword & PEmailValid;
export type PEditProfile = { username: string; phone: string };
export type PEditPhoto = { photo: string };
export type PEditPassword = { OldPassword: string; NewPassword: string; PasswordConfirmation: string };

// ACTION's
interface ILoginPending {
  type: AuthTypes.USER_LOGIN_PENDING;
  payload: null;
}
interface ILoginError {
  type: AuthTypes.USER_LOGIN_ERROR;
  payload: { error: AxiosError };
}
interface ILoginSuccess {
  type: AuthTypes.USER_LOGIN_SUCCESS;
  payload: IUserData;
}
interface IProfilePending {
  type: AuthTypes.GET_PROFILE_PENDING;
  payload: null;
}
interface IProfileError {
  type: AuthTypes.GET_PROFILE_ERROR;
  payload: { error: AxiosError };
}
interface IProfileSuccess {
  type: AuthTypes.GET_PROFILE_SUCCESS;
  payload: Partial<IProfileData>;
}

type IUserLogin = ILoginError | ILoginPending | ILoginSuccess;
type IProfile = IProfileError | IProfilePending | IProfileSuccess;

export type IAuth = IProfile | IUserLogin;
