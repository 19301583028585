import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateEmail } from 'redux/actions/authActions';
import { routePath } from 'utils/exception';

const EmailValidate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const initValidate = async () => {
      const getUrlParams = new URLSearchParams(window.location.search);
      if (!getUrlParams.toString()) history.replace(`${routePath}login`);
      const validate = await validateEmail({
        key: getUrlParams.get('key'),
      });

      if (validate) {
        const redirect = new URLSearchParams();
        redirect.set('email', validate?.data?.data?.Email);
        redirect.set('key', validate?.data?.data?.ForgotPasswordKey);
        history.replace(`${routePath}reset-password/validate?` + redirect.toString());
      } else {
        history.replace(`${routePath}login`);
      }
    };

    initValidate();
  }, [dispatch, history]);

  return <></>;
};

export default EmailValidate;
