export enum PayoutFmsTypes {
  GET_PAYOUT_INDICATED_MERCHANT_TYPE_START = 'GET_PAYOUT_INDICATED_MERCHANT_TYPE_START',
  GET_PAYOUT_INDICATED_MERCHANT_TYPE_SUCCESS = 'GET_PAYOUT_INDICATED_MERCHANT_TYPE_SUCCESS',
  GET_PAYOUT_INDICATED_MERCHANT_TYPE_FAILED = 'GET_PAYOUT_INDICATED_MERCHANT_TYPE_FAILED',

  GET_PAYOUT_AMOUNT_MERCHANT_TYPE_START = 'GET_PAYOUT_AMOUNT_MERCHANT_TYPE_START',
  GET_PAYOUT_AMOUNT_MERCHANT_TYPE_SUCCESS = 'GET_PAYOUT_AMOUNT_MERCHANT_TYPE_SUCCESS',
  GET_PAYOUT_AMOUNT_MERCHANT_TYPE_FAILED = 'GET_PAYOUT_AMOUNT_MERCHANT_TYPE_FAILED',

  GET_PAYOUT_TICKET_MERCHANT_TYPE_START = 'GET_PAYOUT_TICKET_MERCHANT_TYPE_START',
  GET_PAYOUT_TICKET_MERCHANT_TYPE_SUCCESS = 'GET_PAYOUT_TICKET_MERCHANT_TYPE_SUCCESS',
  GET_PAYOUT_TICKET_MERCHANT_TYPE_FAILED = 'GET_PAYOUT_TICKET_MERCHANT_TYPE_FAILED',

  GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_START = 'GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_START',
  GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_SUCCESS = 'GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_SUCCESS',
  GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_FAILED = 'GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_FAILED',

  GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_START = 'GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_START',
  GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_SUCCESS = 'GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_SUCCESS',
  GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_FAILED = 'GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_FAILED',

  GET_PAYOUT_TICKET_MERCHANT_CATEGORY_START = 'GET_PAYOUT_TICKET_MERCHANT_CATEGORY_START',
  GET_PAYOUT_TICKET_MERCHANT_CATEGORY_SUCCESS = 'GET_PAYOUT_TICKET_MERCHANT_CATEGORY_SUCCESS',
  GET_PAYOUT_TICKET_MERCHANT_CATEGORY_FAILED = 'GET_PAYOUT_TICKET_MERCHANT_CATEGORY_FAILED',

  GET_PAYOUT_INDICATED_DATE_START = 'GET_PAYOUT_INDICATED_DATE_START',
  GET_PAYOUT_INDICATED_DATE_SUCCESS = 'GET_PAYOUT_INDICATED_DATE_SUCCESS',
  GET_PAYOUT_INDICATED_DATE_FAILED = 'GET_PAYOUT_INDICATED_DATE_FAILED',

  GET_PAYOUT_LIST_SUSPEND_START = 'GET_PAYOUT_LIST_SUSPEND_START',
  GET_PAYOUT_LIST_SUSPEND_SUCCESS = 'GET_PAYOUT_LIST_SUSPEND_SUCCESS',
  GET_PAYOUT_LIST_SUSPEND_FAILED = 'GET_PAYOUT_LIST_SUSPEND_FAILED',

  GET_PAYOUT_LIST_FRAUD_START = 'GET_PAYOUT_LIST_FRAUD_START',
  GET_PAYOUT_LIST_FRAUD_SUCCESS = 'GET_PAYOUT_LIST_FRAUD_SUCCESS',
  GET_PAYOUT_LIST_FRAUD_FAILED = 'GET_PAYOUT_LIST_FRAUD_FAILED',
}

export interface IPayoutFmsState {
  IndicatedMerchantType: InterfaceMrcTypes;
  TicketMerchantType: InterfaceMrcTypes;
  AmountMerchantType: InterfaceMrcTypes;
  IndicatedMerchantCategory: InterfaceMrcCategory;
  TicketMerchantCategory: InterfaceMrcCategory;
  AmountMerchantCategory: InterfaceMrcCategory;
  IndicatedMerchantDate: InterfaceMrcDate;
  SuspendList: InterfaceList;
  FraudList: InterfaceList;
}

export interface InterfaceMrcTypes {
  isLoading: boolean;
  data: ApiResponseData[];
  message: string;
}

export interface InterfaceMrcCategory {
  isLoading: boolean;
  data: ApiResponseDataCategory[];
  message: string;
}

export interface InterfaceMrcDate {
  isLoading: boolean;
  data: ApiResponseDate[];
  message: string;
}

export interface InterfaceList {
  isLoading: boolean;
  data: ApiResponseSuspend[] | ApiResponseFraud[];
  message: string;
}

export interface ApiResponseData {
  merchant_type?: string;
  total?: string;
}

export interface ApiResponseDataCategory {
  merchant_category?: string;
  total?: string;
}

export interface ApiResponseDate {
  periode?: string;
  total?: string;
}
export interface ApiResponseSuspend {
  date?: string;
  merchant_id?: string;
  merchant_name?: string;
  merchant_type?: string;
  merchant_category?: string;
  merchant_code?: string;
  status?: string;
}

export interface ApiResponseFraud {
  mId?: string | number;
  mCode?: string | number;
  MerchantName?: string;
  Type?: string;
  Category?: string;
  RegisterDate?: string;
  LastTransactionDate?: string;
  Status?: string;
  StatusFraud?: string;
}

interface IGetIndicatedMerchantStart {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_TYPE_START;
  payload: null;
}

interface IGetIndicatedMerchantSuccess {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_TYPE_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetIndicatedMerchantFailed {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_TYPE_FAILED;
  payload: string;
}

interface IGetAmountMerchantStart {
  type: PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_TYPE_START;
  payload: null;
}

interface IGetAmountMerchantSuccess {
  type: PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_TYPE_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetAmountMerchantFailed {
  type: PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_TYPE_FAILED;
  payload: string;
}

interface IGetTicketMerchantStart {
  type: PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_TYPE_START;
  payload: null;
}

interface IGetTicketMerchantSuccess {
  type: PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_TYPE_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetTicketMerchantFailed {
  type: PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_TYPE_FAILED;
  payload: string;
}

interface IGetIndicatedMerchantCategoryStart {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_START;
  payload: null;
}

interface IGetIndicatedMerchantCategorySuccess {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetIndicatedMerchantCategoryFailed {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_FAILED;
  payload: string;
}

interface IGetAmountMerchantCategoryStart {
  type: PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_START;
  payload: null;
}

interface IGetAmountMerchantCategorySuccess {
  type: PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetAmountMerchantCategoryFailed {
  type: PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_FAILED;
  payload: string;
}

interface IGetTicketMerchantCategoryStart {
  type: PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_CATEGORY_START;
  payload: null;
}

interface IGetTicketMerchantCategorySuccess {
  type: PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_CATEGORY_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetTicketMerchantCategoryFailed {
  type: PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_CATEGORY_FAILED;
  payload: string;
}

interface IGetIndicatedDateStart {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_DATE_START;
  payload: null;
}

interface IGetIndicatedDateSuccess {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_DATE_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetIndicatedDateFailed {
  type: PayoutFmsTypes.GET_PAYOUT_INDICATED_DATE_FAILED;
  payload: string;
}

interface IGetListSuspendStart {
  type: PayoutFmsTypes.GET_PAYOUT_LIST_SUSPEND_START;
  payload: null;
}

interface IGetListSuspendSuccess {
  type: PayoutFmsTypes.GET_PAYOUT_LIST_SUSPEND_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetListSuspendFailed {
  type: PayoutFmsTypes.GET_PAYOUT_LIST_SUSPEND_FAILED;
  payload: string;
}

interface IGetListFraudStart {
  type: PayoutFmsTypes.GET_PAYOUT_LIST_FRAUD_START;
  payload: null;
}

interface IGetListFraudSuccess {
  type: PayoutFmsTypes.GET_PAYOUT_LIST_FRAUD_SUCCESS;
  payload: IPayoutFmsState;
}

interface IGetListFraudFailed {
  type: PayoutFmsTypes.GET_PAYOUT_LIST_FRAUD_FAILED;
  payload: string;
}

type IIndicatedMerchantType = IGetIndicatedMerchantFailed | IGetIndicatedMerchantSuccess | IGetIndicatedMerchantStart;
type IAmountMerchantType = IGetAmountMerchantStart | IGetAmountMerchantSuccess | IGetAmountMerchantFailed;
type ITicketMerchantType = IGetTicketMerchantStart | IGetTicketMerchantSuccess | IGetTicketMerchantFailed;
type IIndicatedMerchantCategory =
  | IGetIndicatedMerchantCategoryStart
  | IGetIndicatedMerchantCategorySuccess
  | IGetIndicatedMerchantCategoryFailed;
type IAmountMerchantCategory =
  | IGetAmountMerchantCategoryStart
  | IGetAmountMerchantCategorySuccess
  | IGetAmountMerchantCategoryFailed;
type ITicketMerchantCategory =
  | IGetTicketMerchantCategoryStart
  | IGetTicketMerchantCategorySuccess
  | IGetTicketMerchantCategoryFailed;

type IIndicatedMerchantDate = IGetIndicatedDateStart | IGetIndicatedDateSuccess | IGetIndicatedDateFailed;

type IListSuspend = IGetListSuspendStart | IGetListSuspendSuccess | IGetListSuspendFailed;

type IListFraud = IGetListFraudStart | IGetListFraudSuccess | IGetListFraudFailed;

export type IPayoutFMS =
  | IIndicatedMerchantType
  | IAmountMerchantType
  | ITicketMerchantType
  | IIndicatedMerchantCategory
  | IAmountMerchantCategory
  | ITicketMerchantCategory
  | IIndicatedMerchantDate
  | IListSuspend
  | IListFraud;
