import { Button, ButtonProps, ThemeProvider } from '@mui/material';
import { btnTheme } from 'configs/theme';
import React from 'react';

interface BasicBtn extends ButtonProps {
  // add custom props
}

const Component: React.FC<BasicBtn> = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={btnTheme}>
      <Button {...props}>{children}</Button>
    </ThemeProvider>
  );
};
export default Component;
