import React, { useEffect, useRef, useState } from 'react';
import { BasicTextField } from '..';
import { Calendar, CalendarProps } from 'react-date-range';
import { BasicButton } from 'Components/Buttons';
import moment from 'moment';
import { icons } from 'assets';

type Props = {
  pickedDate: { val: string; set: (v: string) => void };
  placeholder?: string;
} & CalendarProps;

const Component: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const calendarRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(event.target.value);
  };

  const xSubmit = () => {
    let date = moment(selectedDate).format('YYYY-MM-DD');
    const format = date.concat(` ${selectedTime}:00`);
    props.pickedDate?.set(format);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={calendarRef}>
      <BasicTextField
        onClick={() => setIsOpen(true)}
        value={props?.pickedDate?.val ? moment(props.pickedDate.val).format('DD/MM/YYYY - HH:mm') : ''}
        placeholder={props.placeholder}
        endAdornment={<img src={icons.Calendar} alt='' />}
      />
      {isOpen && (
        <div className='flex flex-col bg-white p-2' style={{ position: 'absolute', zIndex: 999 }}>
          <Calendar date={selectedDate} onChange={handleDateChange} {...props} />
          <BasicTextField type='time' value={selectedTime} onChange={handleTimeChange} />
          <BasicButton
            disabled={selectedDate === null || selectedTime === ''}
            sx={{ mt: 1 }}
            variant='contained'
            onClick={xSubmit}
          >
            OK
          </BasicButton>
        </div>
      )}
    </div>
  );
};
export default Component;
