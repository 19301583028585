/* eslint-disable */
import { FmsActions, FmsTypes, IFmsState } from '../types/fmsTypes';

const initialState = {
  loading: false,
  loadtotalIndicated: {
    jumlah_merchant: false,
    total_transaction: false,
    total_user: false,
  },
  loadtotalIncatedCategory: {
    merchant: false,
    transaction: false,
    user: false,
  },
  loadtrendTotalIndicated: {
    merchant: false,
    transaction: false,
    user: false,
  },
  totalIndicated: {
    jumlah_merchant: null,
    total_transaction: null,
    total_user: null,
  },
  totalIncatedCategory: {
    merchant: null,
    transaction: null,
    user: null,
  },
  trendTotalIndicated: {
    merchant: null,
    transaction: null,
    user: null,
  },
  detailIndicated: null,
  listMerchantSus: null,
  error: null,
};

export default (state: IFmsState = initialState, { type, payload }: FmsActions) => {
  switch (type) {
    case FmsTypes.SET_FMS_ERROR:
      return { ...state, ...payload, loading: false, isError: true };

    case FmsTypes.UPDATE_loadtotalIncatedCategory:
      return {
        ...state,
        loadtotalIncatedCategory: { ...state.loadtotalIncatedCategory, ...payload.loadtotalIncatedCategory },
      };
    case FmsTypes.UPDATE_loadtotalIndicated:
      return {
        ...state,
        loadtotalIndicated: { ...state.loadtotalIndicated, ...payload.loadtotalIndicated },
      };
    case FmsTypes.UPDATE_loadtrendTotalIndicated:
      return {
        ...state,
        loadtrendTotalIndicated: { ...state.loadtrendTotalIndicated, ...payload.loadtrendTotalIndicated },
      };

    case FmsTypes.GET_TOTAL_INDICATED:
      return {
        ...state,
        totalIndicated: {
          ...state.totalIndicated,
          ...payload.totalIndicated,
        },
      };
    case FmsTypes.GET_TOTAL_INDICATED_CATEGORY:
      return {
        ...state,
        totalIncatedCategory: {
          ...state.totalIncatedCategory,
          ...payload.totalIncatedCategory,
        },
      };
    case FmsTypes.GET_TREND_TOTAL_INDICATED:
      return {
        ...state,
        trendTotalIndicated: {
          ...state.trendTotalIndicated,
          ...payload.trendTotalIndicated,
        },
      };
    case FmsTypes.GET_DETAIL_INDICATED:
      return {
        ...state,
        ...payload,
      };
    case FmsTypes.GET_LIST_MERCHANT_SUS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
