import { AxiosError } from 'axios';

// Action Types
export enum NotificationType {
  GET_LIST_PENDING = 'GET_LIST_PENDING',
  GET_LIST_SUCCES = 'GET_LIST_SUCCES',
  GET_LIST_TOP_SUCCES = 'GET_LIST_TOP_SUCCES',
  GET_LIST_ERROR = 'GET_LIST_ERROR',
  REFRESH_LIST_SUCCES = 'REFRESH_LIST_SUCCES',
  GET_LIST_CATEGORY = 'GET_LIST_CATEGORY',
  SET_DETAIL = 'SET_DETAIL',
  SET_ISNOTIF = 'SET_ISNOTIF',
  SET_ISDETAIL = 'SET_ISDETAIL',

  SET_DELETE_PENDING = 'SET_DELETE_PENDING',
  SET_DELETE_SUCCES = 'SET_DELETE_SUCCES',
  SET_DELETE_ERROR = 'SET_DELETE_ERROR',
}

// IState Example
export interface INotificationState {
  data: IData;
  category: ICategory[];
  isLoading: boolean;
  isDetail: boolean;
  isNotif: boolean;
  detail: null;
  error: AxiosError;
}

interface ITopic {
  topic_id: number;
  topic_name: string;
}

interface ICategory {
  id: number;
  category_name: string;
  topics: ITopic[];
}

interface IData {
  message_list: msgTopListData[];
  message_top_list: msgTopListData[];
}

export type msgTopListData = {
  id?: number;
  is_read?: number;
  merchant_id?: number;
  message_body?: string;
  message_date?: string;
  message_desc?: string;
  message_name?: string;
  message_schedule_date?: string;
  message_status?: number;
  message_title?: string;
  message_to?: string;
  message_topic?: string;
  message_type?: string;
  redirect_id?: number;
  user_id_receiver?: number;
};

interface GetListAction {
  type: NotificationType.GET_LIST_SUCCES;
  payload: {
    data: any;
  };
}
interface GetListTopAction {
  type: NotificationType.GET_LIST_TOP_SUCCES;
  payload: {
    data: any;
  };
}

interface RefreshListAction {
  type: NotificationType.REFRESH_LIST_SUCCES;
  payload: {
    data: any;
  };
}

interface SetLoadingAction {
  type: NotificationType.GET_LIST_PENDING;
  payload?: any;
}

interface SetErrorAction {
  type: NotificationType.GET_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface SetIsDetail {
  type: NotificationType.SET_ISDETAIL;
  payload: {
    isDetail: boolean;
  };
}

interface getCategory {
  type: NotificationType.GET_LIST_CATEGORY;
  payload: {
    category: ICategory;
  };
}

interface SetDetail {
  type: NotificationType.SET_DETAIL;
  payload: {
    detail: number;
    isDetail: boolean;
  };
}

export type NotificationAction =
  | GetListAction
  | SetLoadingAction
  | SetErrorAction
  | SetIsDetail
  | RefreshListAction
  | getCategory
  | GetListTopAction
  | SetDetail;
