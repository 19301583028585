/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { AuthTypes, IAuth, IAuthState } from 'redux/types/authTypes';

const initialState = {
  isLoading: false,
  userData: {
    ClientId: 0,
    Email: '',
    Fullname: '',
    Phone: null,
    Token: '',
    Username: '',
  },
  profile: {},
  error: null,
};

export default (state: IAuthState = initialState, { type, payload }: IAuth) => {
  switch (type) {
    case AuthTypes.USER_LOGIN_PENDING:
      return update(state, { isLoading: { $set: true } });
    case AuthTypes.USER_LOGIN_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        userData: { $set: payload },
      });
    case AuthTypes.USER_LOGIN_ERROR:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload.error },
      });
    case AuthTypes.GET_PROFILE_PENDING:
      return update(state, { isLoading: { $set: true } });
    case AuthTypes.GET_PROFILE_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        profile: { $set: payload },
      });
    case AuthTypes.GET_PROFILE_ERROR:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload.error },
      });
    default:
      return state;
  }
};
