import Cookies from 'js-cookie';
// disini masih menggunakan localStorage dikarenakan ada limit dalam menggunakan cookie
export const setCookie = (key: string, value: string) => {
  // diganti menjadi session storage by request dari TL
  sessionStorage.setItem(key, value);
};
export const removeCookie = (key: string) => {
  sessionStorage.removeItem(key);
};
export const getCookie = (key: string) => {
  return sessionStorage.getItem(key);
};
export const sessionClear = () => {
  sessionStorage.clear();
};

export const setRealCookie = (key: string, value: string, hour = 6) => {
  const expiresIn = new Date(new Date().getTime() + hour * 60 * 60 * 1000);
  Cookies.set(key, value, { expires: expiresIn, secure: true });
};
export const removeRealCookie = (key: string) => {
  Cookies.remove(key);
};
export const getRealCookie = (key: string) => {
  return Cookies.get(key);
};
