import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { IMasterData, IMasterDataState, IMasterDataTypes } from 'redux/types/masterDataTypes';

const initialState = {
  isLoading: false,
  error: null,
  ListMasterData: [],
  DetailListMasterData: [],
  DataMasterData: {},
  Pagination: {},
};

export default (state: IMasterDataState = initialState, { type, payload }: IMasterData) => {
  switch (type) {
    case IMasterDataTypes.GET_MASTER_DATA_PENDING:
      return update(state, { isLoading: { $set: true }, error: { $set: null } });
    case IMasterDataTypes.GET_MASTER_DATA_FAILED:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload },
        ListMasterData: { $set: [] },
        DetailListMasterData: { $set: [] },
      });
    case IMasterDataTypes.GET_MASTER_DATA_LIST:
      return update(state, {
        isLoading: { $set: false },
        ListMasterData: { $set: payload?.data },
        Pagination: { $set: payload?.paginator },
        error: { $set: null },
      });
    case IMasterDataTypes.GET_MASTER_DATA_DETAIL_LIST:
      return update(state, {
        isLoading: { $set: false },
        DetailListMasterData: { $set: payload },
        error: { $set: null },
      });
    case IMasterDataTypes.SET_DATA_MASTER_DATA:
      return update(state, {
        isLoading: { $set: false },
        DataMasterData: { $set: payload },
        error: { $set: null },
      });

    default:
      return state;
  }
};
