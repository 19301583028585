import { AxiosError } from 'axios';

export enum ITicketingTypes {
  GET_LIST_TICKETING = 'GET_LIST_TICKETING',
  GET_DETAIL_TICKET = 'GET_DETAIL_TICKET',
  GET_DETAIL_BEFORE = 'GET_DETAIL_BEFORE',
  GET_DETAIL_AFTER = 'GET_DETAIL_AFTER',
  GET_IMAGE_BEFORE = 'GET_IMAGE_BEFORE',
  GET_IMAGE_AFTER = 'GET_IMAGE_AFTER',

  GET_TICKET_PENDING = 'GET_TICKET_PENDING',
  GET_TICKET_fAILED = 'GET_TICKET_fAILED',
  SUBMIT_PENDING = 'SUBMIT_PENDING',
  SUBMIT_FAILED = 'SUBMIT_FAILED',
  GET_IMAGE_PENDING = 'GET_IMAGE_PENDING',
  GET_IMAGE_FAILED = 'GET_IMAGE_FAILED',

  STAFF_SUBMIT_SUCCESS = 'STAFF_SUBMIT_SUCCESS',
  SPV_SUBMIT_SUCCESS = 'SPV_SUBMIT_SUCCESS',

  EXPORT_PENDING = 'EXPORT_PENDING',
  EXPORT_FAILED = 'EXPORT_FAILED',
  EXPORT_SUCCESS = 'EXPORT_SUCCESS',

  CHECK_ACCOUNT_START = 'CHECK_ACCOUNT_START',
  CHECK_ACCOUNT_FAILED = 'CHECK_ACCOUNT_FAILED',
  CHECK_ACCOUNT_NUMBER = 'CHECK_ACCOUNT_NUMBER',

  UPLOAD_PROOF_START = 'UPLOAD_PROOF_START',
  UPLOAD_PROOF_FAILED = 'UPLOAD_PROOF_FAILED',
  UPLOAD_PROOF_IMAGE_SUCCESS = 'UPLOAD_PROOF_IMAGE_SUCCESS',
}

// STATE
export interface ITicketingState {
  isLoading: boolean;
  error: AxiosError;
  ListTicket: ResponseListTicketing;
  DetailTicket: DetailTicketData;
  Detail: {
    before?: DetailData;
    after?: DetailData;
  };
}

// DATA
export interface PaymentMethodData {
  id: number;
  name: string;
  pict_url: string;
  Channels?: ChannelData[];
}

interface ChannelData {
  id: number;
  code: string;
  name: string;
  pict_url: string;
}
export interface ResponseListTicketing {
  Code: number;
  Message: string;
  Data?: ListTicketData[];
  Paginate: PaginationData;
}
export interface ListTicketData {
  number: number;
  ticket_queue_id: number;
  client_id: number;
  ticket_id: number;
  ticket_no: string;
  ticket_note: string;
  ticket_detail_origin_json: string;
  ticket_detail?: DataTicketDetail;
  ticket_created_by: number;
  ticket_created_date: string;
  ticket_validated_by: number;
  ticket_validated_date: string;
  ticket_checked_by: number;
  ticket_checked_date: string;
  ticket_process_by: number;
  ticket_process_date: string;
  ticket_approved_by: number;
  ticket_approved_date: string;
  ticket_final_by: number;
  ticket_final_date: string;
  ticket_rejected_by: number;
  ticket_rejected_date: string;
  ticket_status: string;
  ticketing_queue_id: number;
}

export interface DetailTicketData {
  ticket_queue_id?: number;
  client_id?: number;
  ticket_id?: number;
  user_id?: number;
  ticket_no?: string;
  ticket_status?: string;
  ticket_detail_origin_json?: string;
  ticket_detail?: DataTicketDetail;
  ticket_detail_json?: string;
  ticket_created_by?: number;
  ticket_created_date?: string;
  ticket_validated_by?: number;
  ticket_validated_date?: string;
  ticket_checked_by?: number;
  ticket_checked_date?: string;
  ticket_process_by?: number;
  ticket_process_date?: string;
  ticket_approved_by?: number;
  ticket_approved_date?: string;
  ticket_final_by?: number;
  ticket_final_date?: string;
  ticket_rejected_date?: string;
}

export interface DetailData {
  name: string;
  business_type: number;
  package_type: number;
  bis_type_code: string;
  bis_category_code: string;
  bis_url: string;
  finance_email: string;
  finance_phone: string;
  tech_phone: string;
  tech_email: string;
  own_name: string;
  own_address: string;
  own_zip: string;
  own_phone: string;
  own_citizen: string;
  ktp_url: string;
  ktp_name: string;
  ktp_no: string;
  npwp_name: string;
  npwp_number: string;
  npwp_address: string;
  npwp_url: string;
  state: number;
  info_status: string;
  bank: string;
  bank_acc_name: string;
  bank_acc_no: string;
  payments: number[];
}

interface DataTicketDetail {
  client_id: string;
  merchant_code: string;
  merchant_id: string;
  merchant_name: string;
  merchant_email?: string;
  request_by_email: string;
  request_by_name: string;
  request_by_role: string;
  status?: string;
  ticket_id: string;
  ticket_no: string;
  type_ticket: string;
  note?: string;
  ticket_key1_notes?: string;
  ticket_key2_notes?: string;
  ticket_key3_notes?: string;
  // add on type 2
  admin_id?: string;
  admin_name?: string;
  change_status?: string;
  channel?: string;
  channel_no?: string;
  current_status?: string;
  email?: string;
  name?: string;
  national_id_number?: string;
  phone_no?: string;
  reason_to_delete?: string;
  request_date?: string;
  user_id?: string;
  // add on type 3
  total_balance?: string;
  request?: string;
  bank?: string;
  account_name?: string;
  account_no?: string;
  pict_proof?: string;
  // add SLA
  sla_day?: number;
  sla_priority?: string;
  sla_due_date?: string;
}
interface PaginationData {
  current_page: number;
  total_page: number;
  total_row: number;
}

export interface ListQueryTicket {
  permission?: number;
  page?: number;
  limit?: number;
  search?: string;
}

export interface IStaffDataEditProfile {
  ticket_queue_id: number; // DetailTicket
  ticket_id: number; // DetailTicket
  ticket_type: number; // DetailTicket
  balance: number; // biarin 0
  user_id: number; // ambil dari auth Id
  client_id: number; // client id dari merchant (1)
  status: string; // ticket_status yg rubah frontend (setelah diedit di staff dirubah jadi 1)
  admin_id: number; // samain dg user id
  pict_proof: string; // empty string aja
  note: string; // ambil dari pick alasan
  ticket_key1_notes?: string; // ambil dari note untuk dikirim ke spv
  ticket_key2_notes?: string; // ambil dari note untuk dikirim ke spv
  ticket_key3_notes?: string; // ambil dari note untuk dikirim ke spv
  data: {
    ticket_id: string; // samain atas
    ticket_no: string;
    client_id: string; // samain atas
    user_id: string; // samain atas
    type_ticket: string; // samain atas
    merchant_name: string; // DetailTicket
    merchant_id: string; // DetailTicket
    merchant_code: string; // DetailTicket
    merchant_email: string; // DetailTicket
    name?: string; // ambil dari data merchant
    business_type?: number;
    package_type?: number;
    bis_type_code?: string;
    bis_category_code?: string;
    bis_url?: string;
    finance_email?: string;
    finance_phone?: string;
    tech_email?: string;
    tech_phone?: string;
    own_name?: string;
    own_address?: string;
    own_zip?: string;
    own_phone?: string;
    own_citizen?: string;
    ktp_url?: string;
    ktp_name?: string;
    ktp_no?: string;
    npwp_url?: string;
    npwp_name?: string;
    npwp_number?: string;
    npwp_address?: string;
    // account_no: string; // kosongin
    state?: number; // ambil dari after
    info_status?: string;
    bank?: string;
    bank_acc_name?: string;
    bank_acc_no?: string;
    payments?: number[];
    admin_id?: string; // samain atas
    pict_proof?: string; // kosongin empty string
    current_status?: string; // ticket type 2
    change_status?: string; // ticket type 2
    reason_to_delete?: string; // ticket type 2
  };
}

export interface InquiryData {
  accountNo: string;
  bankCode: string;
  bankName: string;
  beneficiaryName?: string;
  code: number;
  message: string;
  result?: InquirySuccess | string;
}
interface InquirySuccess {
  AdditionalData: string;
  Amount: string;
  BankCode: string;
  BeneficiaryName: string;
  BeneficiaryNo: string;
  ResponseCode: string;
  ResponseDescription: string;
  TransactionId: string;
}
// interface InquiryFailed {
//   accountNo: '12345678';
//   bankCode: '002';
//   code: 400;
//   message: 'Response inquiry BAG Failed!';
//   result: 'Account Closed/Not Found';
// }

export type KtpNpwpIMGProp = {
  merchant_id: string;
  file_name_ktp: string;
  file_name_npwp: string;
  type: string;
};

// ACTIONS
interface GetTicketPending {
  type: ITicketingTypes.GET_TICKET_PENDING;
  payload: null;
}
interface GetTicketFailed {
  type: ITicketingTypes.GET_TICKET_fAILED;
  payload: { error: AxiosError };
}
interface GetListTicketing {
  type: ITicketingTypes.GET_LIST_TICKETING;
  payload: ResponseListTicketing;
}
interface GetDetailTicket {
  type: ITicketingTypes.GET_DETAIL_TICKET;
  payload: DetailTicketData;
}

interface GetDetailBefore {
  type: ITicketingTypes.GET_DETAIL_BEFORE;
  payload: DetailData;
}
interface GetDetailAfter {
  type: ITicketingTypes.GET_DETAIL_AFTER;
  payload: DetailData;
}

interface SubmitStaffEdit {
  type: ITicketingTypes.STAFF_SUBMIT_SUCCESS;
  payload: IStaffDataEditProfile;
}

interface ImagePending {
  type: ITicketingTypes.GET_IMAGE_PENDING;
  payload: null;
}
interface ImageAfter {
  type: ITicketingTypes.GET_IMAGE_AFTER;
  payload: { npwp_url: string; ktp_url: string };
}
interface ImageBefore {
  type: ITicketingTypes.GET_IMAGE_BEFORE;
  payload: { npwp_url: string; ktp_url: string };
}
interface ImageError {
  type: ITicketingTypes.GET_IMAGE_FAILED;
  payload: { error: AxiosError };
}

interface ExportPending {
  type: ITicketingTypes.EXPORT_PENDING;
  payload: null;
}
interface ExportError {
  type: ITicketingTypes.EXPORT_FAILED;
  payload: { error: AxiosError };
}
interface ExportTicketingSuccess {
  type: ITicketingTypes.EXPORT_SUCCESS;
  payload: [];
}
interface CheckAccountPending {
  type: ITicketingTypes.CHECK_ACCOUNT_START;
  payload: null;
}
interface CheckAccountFailed {
  type: ITicketingTypes.CHECK_ACCOUNT_FAILED;
  payload: { error: AxiosError };
}
interface CheckAccountSuccess {
  type: ITicketingTypes.CHECK_ACCOUNT_NUMBER;
  payload: any; // sementara
}

type IExport = ExportError | ExportPending | ExportTicketingSuccess;
type IImageUpdate = ImagePending | ImageAfter | ImageBefore | ImageError;
type ICheckAccountNumber = CheckAccountPending | CheckAccountFailed | CheckAccountSuccess;

export type ITicketing =
  | GetTicketPending
  | GetTicketFailed
  | GetListTicketing
  | GetDetailTicket
  | GetDetailBefore
  | GetDetailAfter
  | SubmitStaffEdit
  | IImageUpdate
  | IExport
  | ICheckAccountNumber;
