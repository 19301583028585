import React from 'react';
import { TextField, TextFieldProps, ThemeProvider } from '@mui/material';
import { textFieldTheme } from 'configs/theme';
import { BasicCheckbox } from 'Components/Checkbox';
import { CheckListProp } from 'types/Button';

export const CustomFilterInput: React.FC<TextFieldProps> = ({ type, value, onChange, id, placeholder }) => {
  return (
    <ThemeProvider theme={textFieldTheme}>
      <div className='py-2 px-3'>
        <TextField
          id={id}
          type={type}
          size='small'
          fullWidth
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </ThemeProvider>
  );
};

export const ChexkList: React.FC<CheckListProp> = ({ list, selected }) => {
  const header = [
    { title: 'Failed', value: 1 },
    { title: 'In Process', value: 2 },
    { title: 'Submitted', value: 3 },
    { title: 'Success', value: 4 },
  ];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (event.target.checked) {
      list?.set((prev) => [...prev, value]);
    } else {
      list?.set((prev) => prev.filter((v) => v !== value));
    }
  };

  return (
    <div className='flex flex-col py-2 px-4'>
      <span>Filter status:</span>
      <div className='flex flex-col-reverse'>
        {header?.map((item, idx) => (
          <button key={idx} onClick={() => selected?.set(item.value.toString())} className='flex'>
            <BasicCheckbox
              value={item.value}
              checked={list?.val.includes(item.value)}
              onChange={handleCheckboxChange}
              sx={{ paddingLeft: 0 }}
            />
            <span className='my-auto font-semibold'>{item.title}</span>
          </button>
        ))}
      </div>
    </div>
  );
};
