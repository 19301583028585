import update from 'immutability-helper';
import { IPayoutFMS, IPayoutFmsState, PayoutFmsTypes } from 'redux/types/fmsPayoutTypes';

const initialState = {
  IndicatedMerchantType: {
    isLoading: false,
    data: [],
    message: '',
  },
  TicketMerchantType: {
    isLoading: false,
    data: [],
    message: '',
  },
  AmountMerchantType: {
    isLoading: false,
    data: [],
    message: '',
  },
  IndicatedMerchantCategory: {
    isLoading: false,
    data: [],
    message: '',
  },
  TicketMerchantCategory: {
    isLoading: false,
    data: [],
    message: '',
  },
  AmountMerchantCategory: {
    isLoading: false,
    data: [],
    message: '',
  },
  IndicatedMerchantDate: {
    isLoading : false,
    data: [],
    message: '',
  },
  SuspendList : {
    isLoading: false,
    data: [],
    message: ''
  },
  FraudList : {
    isLoading: false,
    data: [],
    message : ''
  }
};

export default (state: IPayoutFmsState = initialState, { type, payload }: IPayoutFMS) => {
  switch (type) {
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_TYPE_START:
      return update(state, {
        IndicatedMerchantType: { isLoading: { $set: true } },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_TYPE_SUCCESS:
      return update(state, {
        IndicatedMerchantType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_TYPE_FAILED:
      return update(state, {
        IndicatedMerchantType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_TYPE_START:
      return update(state, {
        AmountMerchantType: {
          isLoading: { $set: false },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_TYPE_SUCCESS:
      return update(state, {
        AmountMerchantType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_TYPE_FAILED:
      return update(state, {
        AmountMerchantType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_TYPE_START:
      return update(state, {
        TicketMerchantType: {
          isLoading: { $set: true },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_TYPE_SUCCESS:
      return update(state, {
        TicketMerchantType: {
          isLoading: { $set: false },
          //@ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_TYPE_FAILED:
      return update(state, {
        TicketMerchantType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_START:
      return update(state, {
        IndicatedMerchantCategory: { isLoading: { $set: true } },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_SUCCESS:
      return update(state, {
        IndicatedMerchantCategory: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_MERCHANT_CATEGORY_FAILED:
      return update(state, {
        IndicatedMerchantCategory: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_CATEGORY_START:
      return update(state, {
        TicketMerchantCategory: { isLoading: { $set: true } },
      });
    case PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_CATEGORY_SUCCESS:
      return update(state, {
        TicketMerchantCategory: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_TICKET_MERCHANT_CATEGORY_FAILED:
      return update(state, {
        TicketMerchantCategory: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_START:
      return update(state, {
        AmountMerchantCategory: { isLoading: { $set: true } },
      });
    case PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_SUCCESS:
      return update(state, {
        AmountMerchantCategory: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_AMOUNT_MERCHANT_CATEGORY_FAILED:
      return update(state, {
        AmountMerchantCategory: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_DATE_START:
      return update(state, {
        IndicatedMerchantDate: { isLoading: { $set: true } },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_DATE_SUCCESS:
      return update(state, {
        IndicatedMerchantDate: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case PayoutFmsTypes.GET_PAYOUT_INDICATED_DATE_FAILED:
      return update(state, {
        IndicatedMerchantDate: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
      case PayoutFmsTypes.GET_PAYOUT_LIST_SUSPEND_START:
        return update(state, {
          SuspendList: { isLoading: { $set: true } },
        });
      case PayoutFmsTypes.GET_PAYOUT_LIST_SUSPEND_SUCCESS:
        return update(state, {
          SuspendList: {
            isLoading: { $set: false },
            // @ts-ignore
            data: { $set: payload },
          },
        });
      case PayoutFmsTypes.GET_PAYOUT_LIST_SUSPEND_FAILED:
        return update(state, {
          SuspendList: {
            isLoading: { $set: false },
            message: { $set: payload },
          },
        });
      case PayoutFmsTypes.GET_PAYOUT_LIST_FRAUD_START:
        return update(state, {
          FraudList: { isLoading: { $set: true } },
        });
      case PayoutFmsTypes.GET_PAYOUT_LIST_FRAUD_SUCCESS:
        return update(state, {
          FraudList: {
            isLoading: { $set: false },
            // @ts-ignore
            data: { $set: payload },
          },
        });
      case PayoutFmsTypes.GET_PAYOUT_LIST_FRAUD_FAILED:
        return update(state, {
          FraudList: {
            isLoading: { $set: false },
            message: { $set: payload },
          },
        });
    default:
      return state;
  }
};
