/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { IUtils, IUtilsState, UtilsType } from 'redux/types/utilsTypes';

const initialState = {
  toolbar: '',
  content: 'user',
  lang: 'id',
};

export default (state: IUtilsState = initialState, { type, payload }: IUtils) => {
  switch (type) {
    case UtilsType.SET_TOOLBAR:
      return update(state, { toolbar: { $set: payload } });
    case UtilsType.CHANGE_DRAWER_CONTENT:
      return update(state, { content: { $set: payload } });
    case UtilsType.SWITCH_LANG:
      return update(state, { lang: { $set: payload } });
    default:
      return state;
  }
};
