import React from 'react';
import { Checkbox, CheckboxProps, ThemeProvider } from '@mui/material';
import { checkboxTheme } from 'configs/theme';

const Component: React.FC<CheckboxProps> = (props) => {
  return (
    <ThemeProvider theme={checkboxTheme}>
      <Checkbox color='primary' className='my-auto' {...props} />
    </ThemeProvider>
  );
};

export default Component;
