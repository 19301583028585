import React, { useEffect, useRef, useState } from 'react';
import { BasicTextField } from '..';
import { Calendar, CalendarProps } from 'react-date-range';
import moment from 'moment';

type Props = {
  pickedDate: { val: string; set: (v: string) => void };
  placeholder?: string;
} & CalendarProps;
const Component: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (date: Date) => {
    props.pickedDate?.set(moment(date).format('YYYY-MM-DD'));
    setSelectedDate(date);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={calendarRef}>
      <BasicTextField
        onClick={() => setIsOpen(true)}
        value={selectedDate ? props.pickedDate?.val : ''}
        placeholder={props.placeholder}
      />
      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 999 }}>
          <Calendar date={selectedDate} onChange={handleDateChange} {...props} />
        </div>
      )}
    </div>
  );
};
export default Component;
