import { AxiosError } from 'axios';

export enum IMasterDataTypes {
  GET_MASTER_DATA_LIST = 'GET_MASTER_DATA_LIST',
  GET_MASTER_DATA_DETAIL_LIST = 'GET_MASTER_DATA_DETAIL_LIST',
  GET_MASTER_DATA_PENDING = 'GET_MASTER_DATA_PENDING',
  GET_MASTER_DATA_FAILED = 'GET_MASTER_DATA_FAILED',
  GET_MASTER_DATA_SUCCESS = 'GET_MASTER_DATA_SUCCESS',
  SET_MASTER_DATA_FORM = 'SET_MASTER_DATA_FORM',
  SET_MASTER_DATA_KEY_FIELD = 'SET_MASTER_DATA_KEY_FIELD',
  SET_MASTER_DATA_CREATE_KEY = 'SET_MASTER_DATA_CREATE_KEY',
  CRUD_MASTER_DATA_DETAIL = 'CRUD_MASTER_DATA_DETAIL',
  SET_DATA_MASTER_DATA = 'SET_DATA_MASTER_DATA',
  UPLOAD_IMAGE_MASTER_DATA = 'UPLOAD_IMAGE_MASTER_DATA',
}

export interface DataListMasterData {
  CreateBy: string;
  CreateDate: string;
  Db: string;
  DeleteKey: string;
  DeleteValue: number;
  Description: string;
  Field: string;
  Id: number;
  KeyField: string;
  Name: string;
  Status: number;
  Table: string;
  TotalRecord: number;
  UseFor: number;
}

export interface IMasterDataState {
  isLoading?: boolean;
  error?: string;
  ListMasterData?: DataListMasterData[];
  DetailListMasterData?: any[];
  DataMasterData?: any;
  Pagination?: any;
}

export interface IUploadImg {
  MasterData: any;
}
export interface IBodyDataList {
  DB: string;
  Page: number;
  From: number;
  To: number;
  Sort: string;
}

export interface IBodyDataDetailList {
  DB: string;
  Table: string;
  Key: number;
  From: number;
  To: number;
  Command: string;
  Sort: string;
  Page: number;
}

export interface IPaginationMasterData {
  CurrentPage: number;
  NextPage: number;
  PerPage: number;
  TotalPage: number;
  TotalRow: number;
}

interface GetMasterDataPending {
  type: IMasterDataTypes.GET_MASTER_DATA_PENDING;
  payload: null;
}
interface GetMasterDataFailed {
  type: IMasterDataTypes.GET_MASTER_DATA_FAILED;
  payload: any;
}

interface GetListMasterData {
  type: IMasterDataTypes.GET_MASTER_DATA_LIST;
  payload: { data: DataListMasterData[]; paginator: IPaginationMasterData };
}
interface GetDetailListMasterData {
  type: IMasterDataTypes.GET_MASTER_DATA_DETAIL_LIST;
  payload: [];
}
interface SetMasterDataFormField {
  type: IMasterDataTypes.SET_MASTER_DATA_FORM;
  payload: any;
}
interface SetMasterDataKeyField {
  type: IMasterDataTypes.SET_MASTER_DATA_KEY_FIELD;
  payload: any;
}
interface SetMasterDataCreateKey {
  type: IMasterDataTypes.SET_MASTER_DATA_CREATE_KEY;
  payload: any;
}
interface SetDataMasterData {
  type: IMasterDataTypes.SET_DATA_MASTER_DATA;
  payload: any;
}

interface CRUDMasterDataDetail {
  type: IMasterDataTypes.CRUD_MASTER_DATA_DETAIL;
  payload: any;
}

interface UploadImageMasterData {
  type: IMasterDataTypes.UPLOAD_IMAGE_MASTER_DATA;
  payload: IUploadImg;
}

export type IMasterData =
  | GetMasterDataPending
  | GetMasterDataFailed
  | GetListMasterData
  | GetDetailListMasterData
  | SetDataMasterData
  | CRUDMasterDataDetail
  | UploadImageMasterData;
