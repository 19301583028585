import { AxiosError } from 'axios';

export enum ReportTypes {
  GET_REPORT_TRANSACTION_SUCCESS = 'GET_REPORT_TRANSACTION_SUCCESS',
  GET_REPORT_TRANSACTION_DETAIL_SUCCESS = 'GET_REPORT_TRANSACTION_DETAIL_SUCCESS',
  GET_REPORT_SETTLEMENT_SUCCESS = 'GET_REPORT_SETTLEMENT_SUCCESS',
  GET_REPORT_WITHDRAWAL_SUCCESS = 'GET_REPORT_WITHDRAWAL_SUCCESS',
  GET_REPORT_DONATION_SUCCESS = 'GET_REPORT_DONATION_SUCCESS',
  GET_REPORT_DONATION_TRANSFER_SUCCESS = 'GET_REPORT_DONATION_TRANSFER_SUCCESS',
  GET_REPORT_INSURANCE_SUCCESS = 'GET_REPORT_INSURANCE_SUCCESS',
  GET_REPORT_INSURANCE_TRANSFER_SUCCESS = 'GET_REPORT_INSURANCE_TRANSFER_SUCCESS',
  GET_REPORT_JOURNAL_SUCCESS = 'GET_REPORT_JOURNAL_SUCCESS',
  GET_REPORT_JOURNAL_SUMMARY_SUCCESS = 'GET_REPORT_JOURNAL_SUMMARY_SUCCESS',
  GET_REPORT_PENDING = 'GET_REPORT_PENDING',
  GET_REPORT_PENDING_CHILD = 'GET_REPORT_PENDING_CHILD',
  GET_REPORT_ERROR = 'GET_REPORT_ERROR',
  GET_PARAMS_TRANSACTION = 'GET_PARAMS_TRANSACTION',
  GET_MASTER_MERCHANT_PROD = 'GET_MASTER_MERCHANT_PROD',
  GET_REPORT_WITHDRAWALS_SUCCESS = 'GET_REPORT_WITHDRAWALS_SUCCESS',
  GET_PARAMS_WITHDRAWALS = 'GET_PARAMS_WITHDRAWALS',
  GET_REPORT_WITHDRAWAL_DETAIL_SUCCESS = 'GET_REPORT_WITHDRAWAL_DETAIL_SUCCESS',
  EXPORT_WITHDRAWAL_SUCCESS = 'EXPORT_WITHDRAWAL_SUCCESS',
  EXPORT_TRANSACTION_SUCCESS = 'EXPORT_TRANSACTION_SUCCESS',
  EXPORT_PENDING = 'EXPORT_PENDING',
  EXPORT_ERROR = 'EXPORT_ERROR',
  GET_MUTATION_LIST = 'GET_MUTATION_LIST',
  GET_MUTATION_ERROR = 'GET_MUTATION_ERROR',
  GET_MUTATION_START = 'GET_MUTATION_START',
}

// State
export interface IReportState {
  loading: boolean;
  loadingChild: boolean;
  donation?: unknown[];
  donationTransfer?: unknown[];
  insurance?: unknown[];
  insuranceTransfer?: unknown[];
  transaction: TransactionsData[];
  transactionDetail: DetailTransaction;
  settlement?: unknown[];
  withdrawal?: unknown[];
  withdrawalList?: unknown[];
  withDrawalDetail?: DetailWithDrawal;
  journal?: unknown[];
  journalSummary?: {};
  pagination: PaginationData;
  error?: AxiosError;
  paramsTransaction?: any;
  paramsWithdrawal?: any;
  masterMerchantProd: any;
  mutation?: {
    data: MutationListData;
    paginate: MutationPageData;
  };
}

// Data
export interface TransactionsData {
  amount_initial: number;
  amount_trx: number;
  channel_name: string;
  customer_email: string;
  date: string;
  fee: number;
  merchant_name: string;
  reference_no: string;
  settlement_date: string;
  settlement_due: string;
  state: string;
  status: string;
  third_party_fee: number;
  trx_no: string;
  va_no: string;
}

export interface DetailTransaction {
  custommer?: {
    email: string;
    name: string;
    phone: string;
  };
  items?: {
    brand: string;
    category: string;
    id: string;
    merchantname: string;
    name: string;
    price: number;
    quantity: number;
  }[];
  payment?: {
    channel: string;
    expired_date: string;
    method: string;
    va_number: string;
    payment_code: string;
  };
  summary?: {
    order_id: number;
    reference_no: string;
    status: string;
    trx_date: string;
    trx_no: string;
    initial_amount?: number;
  };
  timeline?: {
    code: string;
    date: string;
    type: string;
  }[];
}

export interface DetailWithDrawal {
  summary?: {
    amount_initial: number;
    amount_trx: number;
    fee_settled: number;
    fee_third_party: number;
    withdrawn: number;
    fee_mdr?: number;
    fee_qoin?: number;
  };
  settlement_summary?: {
    amount_settlement: number;
    total_amount_initial: number;
    total_amount_trx: number;
    total_fee_mdr: number;
    total_fee_qoin: number;
    total_fee_settled: number;
    total_fee_third_party: number;
  };
  pagination?: {
    next: number;
    previous: number;
    total_page: number;
    total_records: number;
  };
  transactions?: {
    amount: number;
    channel: string;
    fee_settled: number;
    fee_third_party: number;
    initial_amount: number;
    reference_no: string;
    trx_no: string;
  }[];
  withdraw_information?: {
    date: string;
    receipt_no: string;
    status: string;
    total_records: number;
    trx_no: string;
    merchant_code?: string;
    merchant_name?: string;
  };
  settlement_information?: {
    settlement_date: string;
    receipt_no: string;
    settlement_status: string;
    total_records: number;
    settlement_no: string;
    merchant_code?: string;
    merchant_name?: string;
  };
  transfer?: {
    tf_number?: number;
    bank_name?: string;
    account_number?: number;
    account_name?: string;
    date?: string;
    amount?: number;
    bank_fee?: number;
    book_fee?: number;
    payout_fee?: number;
    tf_amount?: number;
    tf_status?: string;
  };
  transfer_summary?: {
    account_name: string;
    account_number: string;
    amount_settlement: number;
    amount_transfer: number;
    bank_name: string;
    bank_transfer_fee: number;
    fee_overbooking: number;
    fee_ppayout_party: number;
    transfer_date: string;
    transfer_number: string;
    transfer_status: string;
  };
}

export interface WithDrawalsData {
  amount_initial: number;
  amount_trx: number;
  amount_withdrawn: number;
  date: string;
  fee_settled: number;
  fee_third_party: number;
  receipt_no: string | number;
  state: string;
  status: string;
  total_trx_record: number;
  trx_id: number;
  trx_no: string;
}

export interface PaginationData {
  next?: number;
  previous?: number;
  total_page?: number;
  total_records?: number;
}

export type MutationData = {
  id: number;
  transaction_date: string;
  merchant_id: number;
  description: string;
  type_mutation: number;
  debit: number;
  credit: number;
  balance: number;
  info: string;
};
export type MutationListData = {
  data: MutationData[];
  count: number;
  total_in: number;
  total_out: number;
  current_balance: number;
};
export type MutationPageData = {
  current_items_count: number;
  current_page_number: number;
  has_next_page: boolean;
  has_prev_page: boolean;
  next_page_number: number;
  next_page_url: string;
  offset: number;
  prev_page_number: number;
  prev_page_url: string;
  requested_page_size: number;
  total_pages_count: number;
  total_record: number;
};

// Action
interface IReportPending {
  type: ReportTypes.GET_REPORT_PENDING;
  payload: null;
}
interface IReportPendingChild {
  type: ReportTypes.GET_REPORT_PENDING_CHILD;
  payload: null;
}
interface IReportError {
  type: ReportTypes.GET_REPORT_ERROR;
  payload: { error: AxiosError };
}
interface ITransactionSuccess {
  type: ReportTypes.GET_REPORT_TRANSACTION_SUCCESS;
  payload: {
    transactions: TransactionsData[];
    pagination: PaginationData;
  };
}
interface IWithDrawalsListSuccess {
  type: ReportTypes.GET_REPORT_WITHDRAWALS_SUCCESS;
  payload: {
    withdrawal_lists: WithDrawalsData[];
    pagination: PaginationData;
  };
}
interface ITransactionDetailSuccess {
  type: ReportTypes.GET_REPORT_TRANSACTION_DETAIL_SUCCESS;
  payload: DetailTransaction;
}
interface IWithDrawalDetailSuccess {
  type: ReportTypes.GET_REPORT_WITHDRAWAL_DETAIL_SUCCESS;
  payload: DetailWithDrawal;
}

interface IExportPending {
  type: ReportTypes.EXPORT_PENDING;
  payload: null;
}
interface IExportErrpr {
  type: ReportTypes.EXPORT_ERROR;
  payload: { error: AxiosError };
}
interface IExportTransactionSuccess {
  type: ReportTypes.EXPORT_TRANSACTION_SUCCESS;
  payload: [];
}
interface IExportWithDrawalSuccess {
  type: ReportTypes.EXPORT_WITHDRAWAL_SUCCESS;
  payload: [];
}

interface IParamTransaction {
  type: ReportTypes.GET_PARAMS_TRANSACTION;
  payload: [];
}
interface IParamWithdrawals {
  type: ReportTypes.GET_PARAMS_WITHDRAWALS;
  payload: [];
}
interface IMasterMerchantProd {
  type: ReportTypes.GET_MASTER_MERCHANT_PROD;
  payload: [];
}
interface ITransferMutationSuccess {
  type: ReportTypes.GET_MUTATION_LIST;
  payload: { data: MutationListData; paginate: MutationPageData };
}
interface ITransferMutationFail {
  type: ReportTypes.GET_MUTATION_ERROR;
  payload: string;
}
interface ITransferMutationStart {
  type: ReportTypes.GET_MUTATION_START;
  payload: string;
}

type IExport = IExportErrpr | IExportPending | IExportTransactionSuccess | IExportWithDrawalSuccess;
type IMutation = ITransferMutationSuccess | ITransferMutationFail | ITransferMutationStart;

export type IReport =
  | IReportPending
  | IReportPendingChild
  | IReportError
  | ITransactionSuccess
  | ITransactionDetailSuccess
  | IExport
  | IParamTransaction
  | IMasterMerchantProd
  | IWithDrawalsListSuccess
  | IWithDrawalDetailSuccess
  | IParamWithdrawals
  | IMutation;
