/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { ISettlement, ISettlementState, SettlementTypes } from 'redux/types/settlementTypes';

const initialState = {
  third_party: '',
  id: '',
  is_match: '',
  enabled: {
    settlement: false,
    bank: false,
  },
  settlement_data: [],
  settlement_detail: [],
  detail_header: {},
  bank_statement: [],
  date: '',
  pagination: { current_page: 1, records: 0, total_page: 0 },
  partner: [],
  isLoading: false,
  error: null,
  masterPayment: [],
};

export default (state: ISettlementState = initialState, { type, payload }: ISettlement) => {
  switch (type) {
    case SettlementTypes.SET_THIRD_PARTY:
      return update(state, { third_party: { $set: payload } });
    case SettlementTypes.SET_SETTLEMENT_DATA:
      return update(state, { isLoading: { $set: false }, settlement_data: { $set: payload } });
    case SettlementTypes.SET_BANK_STATEMENT:
      return update(state, { isLoading: { $set: false }, bank_statement: { $set: payload } });
    case SettlementTypes.SET_SETTLEMENT_RECONSILE:
      return update(state, {
        id: { $set: payload.id },
        is_match: { $set: payload.is_match },
        enabled: {
          settlement: {
            $set: payload.enabled.settlement || state.enabled.settlement,
          },
          bank: { $set: payload.enabled.bank || state.enabled.bank },
        },
        date: { $set: payload.date || state.date },
      });
    case SettlementTypes.GET_SETTLEMENT_START:
      return update(state, { isLoading: { $set: true } });
    case SettlementTypes.GET_SETTLEMENT_FAILED:
      return update(state, { isLoading: { $set: false }, error: { $set: payload.error } });
    case SettlementTypes.GET_SETTLEMENT_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        settlement_data: { $set: payload.data },
        pagination: { $set: payload.pagination },
      });
    case SettlementTypes.GET_SETTLEMENT_DETAIL:
      return update(state, {
        isLoading: { $set: false },
        settlement_detail: { $set: payload.detail },
        detail_header: { $set: payload.header },
        pagination: { $set: payload.pagination },
      });
    case SettlementTypes.GET_PARTNER_START:
      return update(state, { isLoading: { $set: true } });
    case SettlementTypes.GET_PARTNER_LIST:
      return update(state, { isLoading: { $set: false }, partner: { $set: payload } });
    case SettlementTypes.GET_PARTNER_FAILED:
      return update(state, { isLoading: { $set: false }, error: { $set: payload.error } });
    case SettlementTypes.GET_PAYMENT_START:
      return update(state, { isLoading: { $set: true } });
    case SettlementTypes.GET_PAYMENT_LIST:
      return update(state, { isLoading: { $set: false }, masterPayment: { $set: payload } });
    case SettlementTypes.GET_PAYMENT_FAILED:
      return update(state, { isLoading: { $set: false }, error: { $set: payload.error } });
    default:
      return state;
  }
};
