/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { AdminTaskState, AdminTaskTypes, IAdminTask } from 'redux/types/adminTasksTypes';

const initialState: AdminTaskState = {
  isLoading: false,
  error: null,
  DetailTask: {
    create_date: '',
    detail_data: {},
    id: null,
    type: null,
  },
  ListTask: {
    list_task: [],
    total_initiate: null,
    total_records: null,
  },
  DetailPayout: {
    create_date: '',
    id: null,
    status: null,
    type: null,
    detail_data: null,
  },
};

export default (state = initialState, { type, payload }: IAdminTask) => {
  switch (type) {
    case AdminTaskTypes.FETCH_PENDING:
      return update(state, { isLoading: { $set: true } });
    case AdminTaskTypes.FETCH_FAILED:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload.error },
        DetailTask: { $set: initialState.DetailTask },
        ListTask: { $set: initialState.ListTask },
        DetailPayout: { $set: initialState.DetailPayout },
      });
    case AdminTaskTypes.GET_LIST_TASK_SUCCESS:
      return update(state, { isLoading: { $set: false }, ListTask: { $set: payload } });
    case AdminTaskTypes.GET_DETAIL_SUCCESS:
      return update(state, { isLoading: { $set: false }, DetailTask: { $set: payload } });
    case AdminTaskTypes.GET_DETAIL_PAYOUT_SUCCESS:
      return update(state, { isLoading: { $set: false }, DetailPayout: { $set: payload } });
    default:
      return state;
  }
};
