import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import { btnTheme } from 'configs/theme';

type ResetBtnProp = {
  onClick?: () => void;
};

const Component: React.FC<ResetBtnProp> = ({ onClick }) => {
  return (
    <ThemeProvider theme={btnTheme}>
        <Button variant='outlined' color='error' sx={{ border: '0' }} className='w-full' onClick={onClick}>
          reset
        </Button>
    </ThemeProvider>
  );
};

export default Component;
