import React, { useState } from 'react';
import {
  createTheme,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  ThemeProvider,
} from '@mui/material';
import { icons } from 'assets';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const TextProps = createTheme({ typography: { fontFamily: 'Manrope' } });

// if tooltip === true, you need to define the type of tooltip
type withIcon = { tooltip: 'true'; tootiptype: '!' | '?' };
type withoutIcon = { tooltip: 'false' };

type ICustomTextField = OutlinedInputProps & {
  // adds custom props
  text: string;
  errortext?: string;
  ispass?: 'true' | 'false';
} & (withIcon | withoutIcon);

const Component: React.FC<ICustomTextField> = (props) => {
  // const { disabled = false, text, errortext, tooltip } = props;
  const [show, setShow] = useState(false);
  const iconVariant = () => {
    if (props.tooltip === 'true') {
      if (props.tootiptype === '?') {
        return icons.infoQ;
      } else if (props.tootiptype === '!') {
        return icons.infoError;
      }
    }
  };

  const xClick = () => {
    setShow((v) => !v);
  };
  const PassAddorment = () => {
    if (props.ispass === 'true') {
      const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };
      return (
        <InputAdornment position='end'>
          <IconButton aria-label='toggle password visibility' onClick={xClick} onMouseDown={onMouseDown} edge='end'>
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      );
    }
  };
  return (
    <ThemeProvider theme={TextProps}>
      <div className='flex flex-col py-2 gap-2'>
        <div className='flex gap-2'>
          <p className='my-auto'>{props.text}</p>
          {props.tooltip === 'true' && (
            <IconButton size='small'>
              <img src={iconVariant()} alt='q' className='w-6' />
            </IconButton>
          )}
        </div>
        <OutlinedInput
          disabled={props.disabled}
          fullWidth
          sx={{ bgcolor: props.disabled && '#E9ECEF' }}
          type={props.ispass ? (show ? 'text' : 'password') : props.type}
          endAdornment={<PassAddorment />}
          {...props}
        />
        {props.error && (
          <FormHelperText error className='flex gap-2'>
            <img src={icons.infoError} alt='err' />
            <span className='my-auto text-base'>{props.errortext}</span>
          </FormHelperText>
        )}
      </div>
    </ThemeProvider>
  );
};
export default Component;
