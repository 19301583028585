import BasicCheckbox from './BasicCheckbox';

const handleCheck = (
  event: React.ChangeEvent<HTMLInputElement>,
  checked: string[],
  setChecked: (checked: string[]) => void
) => {
  let updateList = [...checked];
  if (event.target.checked) {
    updateList = [...checked, event.target.value];
  } else {
    updateList.splice(checked.indexOf(event.target.value), 1);
  }
  setChecked(updateList);
};

export { BasicCheckbox, handleCheck };
