/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { IMercPreview, IMercPreviewState, IMercPreviewTypes } from 'redux/types/merchantPreviewTypes';

const initialState = {
  bizType: [],
  paymentMethod: [],
  isLoading: false,
  error: null,
};

export default (state: IMercPreviewState = initialState, { type, payload }: IMercPreview) => {
  switch (type) {
    case IMercPreviewTypes.GET_MERCHANT_PENDING:
      return update(state, { isLoading: { $set: true } });

    case IMercPreviewTypes.GET_BIZ_SUCCESS:
      return update(state, { isLoading: { $set: false }, bizType: { $set: payload } });
    case IMercPreviewTypes.GET_METODE_PEMBAYARAN_SUCCESS:
      return update(state, { isLoading: { $set: false }, paymentMethod: { $set: payload } });

    case IMercPreviewTypes.GET_MERCHANT_FAILED:
      return update(state, { isLoading: { $set: false }, error: { $set: payload.error } });
    default:
      return state;
  }
};
