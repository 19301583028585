export const noUndef = (value: string | number) => {
  return value !== undefined ? value?.toString() : '';
};
export const isFull = window.location.href.includes('full');
export const routePath = isFull ? '/full/' : '/';
const requiredState = (v) => {
  switch (typeof v) {
    case 'string':
      return v === '';
    case 'number':
      return v === null;
    default:
      break;
  }
};

export { requiredState as reqState };
