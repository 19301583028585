import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import { Sort } from '@mui/icons-material';
import { btnTheme } from 'configs/theme';
import { BasicPopover } from 'Components/Popover';
import { SortBtnProps } from 'types/Button';

const dummy = ['option abc', 'option def', 'option ghi', 'option jkl'];

interface IInterface {
  label?: string;
  title?: string;
}

interface ICombination extends IInterface, SortBtnProps {}

const Component: React.FC<ICombination> = ({ list, select, label, title }) => {
  const anchorRef = React.useRef(null);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [selected, setSelected] = React.useState('');

  const newList = list || dummy;

  const handleToggle = (item) => {
    setSortOpen(!sortOpen);
    select?.set(item);
  };

  return (
    <ThemeProvider theme={btnTheme}>
      <Button
        variant='outlined'
        color='inherit'
        className='gap-2 cursor-pointer'
        onClick={() => setSortOpen(!sortOpen)}
        ref={anchorRef}
      >
        <Sort fontSize='small' />
        <span>{title || 'sort by'}</span>
      </Button>

      <BasicPopover open={sortOpen} anchorEl={anchorRef.current} onClose={() => setSortOpen(false)}>
        <div className='text-[#94A3B8] px-4 py-2'>{label || 'Sort data by'}:</div>
        {newList.map((item, idx) => (
          <button
            key={idx}
            onClick={() => handleToggle(item)}
            className={`${
              item === select?.val ? 'text-[#21AF7D] bg-[#F6F6F6]' : ''
            } p-4 text-start font-bold capitalize`}
          >
            {item}
          </button>
        ))}
      </BasicPopover>
    </ThemeProvider>
  );
};

export default Component;
