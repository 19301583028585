import { AxiosError } from 'axios';

export enum FmsTypes {
  SET_FMS_ERROR = 'SET_FMS_ERROR',

  GET_TOTAL_INDICATED = 'GET_TOTAL_INDICATED',
  GET_TOTAL_INDICATED_CATEGORY = 'GET_TOTAL_INDICATED_CATEGORY',
  GET_TREND_TOTAL_INDICATED = 'GET_TREND_TOTAL_INDICATED',
  GET_DETAIL_INDICATED = 'GET_DETAIL_INDICATED',
  GET_LIST_MERCHANT_SUS = 'GET_LIST_MERCHANT_SUS',

  UPDATE_loadtotalIndicated = 'UPDATE_loadtotalIndicated',
  UPDATE_loadtotalIncatedCategory = 'UPDATE_loadtotalIncatedCategory',
  UPDATE_loadtrendTotalIndicated = 'UPDATE_loadtrendTotalIndicated',
}

export interface IFmsState {
  loadtotalIndicated: IloadtotalIndicated;
  loadtotalIncatedCategory: IloadtotalIncatedCategory;
  loadtrendTotalIndicated: IloadtrendTotalIndicated;
  totalIndicated: ITotalIndicated;
  totalIncatedCategory: {
    merchant: iDataBar;
    transaction: iDataBar;
    user: iDataBar;
  };
  trendTotalIndicated: {
    merchant: iDataLine;
    transaction: iDataLine;
    user: iDataLine;
  };
  detailIndicated: Array<IDetailIndicated>;
  listMerchantSus: Array<IListMerchantSus>;
  error?: AxiosError;
}

interface IloadtotalIndicated {
  jumlah_merchant: boolean;
  total_transaction: boolean;
  total_user: boolean;
}

interface IloadtotalIncatedCategory {
  merchant: boolean;
  transaction: boolean;
  user: boolean;
}

interface IloadtrendTotalIndicated {
  merchant: boolean;
  transaction: boolean;
  user: boolean;
}

export interface IDetailIndicated {
  date: string;
  total_transaction: string;
  total_user: string;
  merchant_category: string;
  merchant_name: string;
  merchant_no: string;
  total_amount: string;
}

export interface IListMerchantSus {
  merchant_category: string;
  merchant_id: string;
  merchant_name: string;
  merchant_type: string;
  merchant_no: string;
  register_date: string;
  status: string;
}

export interface ITotalIndicated {
  jumlah_merchant: number;
  total_transaction: number;
  total_user: number;
}

export interface iDataBar {
  labels: Array<string>;
  jumlahTransaksi: Array<string>;
}

export interface iDataLine {
  labels: Array<string>;
  data: Array<string>;
}

interface IFmsdError {
  type: FmsTypes.SET_FMS_ERROR;
  payload: { error: AxiosError };
}

interface IFmsTotalIndicated {
  type: FmsTypes.GET_TOTAL_INDICATED;
  payload: {
    totalIndicated: object;
  };
}

interface IFmsTotalIndicatedCategory {
  type: FmsTypes.GET_TOTAL_INDICATED_CATEGORY;
  payload: {
    totalIncatedCategory: object;
  };
}

interface IFmsTrendTotalIndicated {
  type: FmsTypes.GET_TREND_TOTAL_INDICATED;
  payload: {
    trendTotalIndicated: object;
  };
}

interface IFmsDetailIndicated {
  type: FmsTypes.GET_DETAIL_INDICATED;
  payload: {
    detailIndicated: object;
  };
}

interface IFmsListMerchantSus {
  type: FmsTypes.GET_LIST_MERCHANT_SUS;
  payload: {
    listMerchantSus: object;
  };
}

interface ISETloadtotalIndicated {
  type: FmsTypes.UPDATE_loadtotalIndicated;
  payload: {
    loadtotalIndicated: object;
  };
}
interface ISETloadtotalIncatedCategory {
  type: FmsTypes.UPDATE_loadtotalIncatedCategory;
  payload: {
    loadtotalIncatedCategory: object;
  };
}
interface ISETloadtrendTotalIndicated {
  type: FmsTypes.UPDATE_loadtrendTotalIndicated;
  payload: {
    loadtrendTotalIndicated: object;
  };
}

export type FmsActions =
  | ISETloadtrendTotalIndicated
  | ISETloadtotalIncatedCategory
  | ISETloadtotalIndicated
  | IFmsdError
  | IFmsTotalIndicated
  | IFmsListMerchantSus
  | IFmsTotalIndicatedCategory
  | IFmsTrendTotalIndicated
  | IFmsDetailIndicated;
