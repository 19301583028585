import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ErrorType } from 'redux/types/errorTypes';

export const errorGlobal = (error: AxiosError) => ({
  type: ErrorType.ERROR_SUCCESS,
  payload: { error },
});

export const handleError = (e: unknown, axiosNotif?: boolean) => {
  let msg: string;
  if (e instanceof Error) {
    if (axios.isAxiosError(e)) {
      msg = e.response.data?.['message'];
    } else msg = e.message;
  } else if (e && typeof e === 'object' && 'message' in e) {
    msg = e.message as string;
  } else if (typeof e === 'string') {
    msg = e;
  } else {
    msg = 'Something went wrong';
    if (axios.isAxiosError(e)) {
      console.log('axios', e.response.data);
    }
  }
  // msg = 'Error'; // generalize all message error
  if (msg === undefined) msg = 'Something went wrong'; // to avoid error of undefined value
  if (axiosNotif) toast.error(msg);
  return msg;
};
