export { default } from './Component';

export interface Props {
  text: { val: string; set: (val: string) => void };
  captchaId?: { val: string; set: (val: string) => void };
  isFail?: { val: boolean; set: (val: boolean) => void };
}

export function captchaForm(data: FormData, id: string, text: string) {
  if (id) data.append('Id', id);
  if (text) data.append('VerifyValue', text);
}
