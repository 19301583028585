import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { getCaptcha } from 'redux/actions/utilsActions';
import { Props } from '.';
import { BasicTextField } from 'Components/Input';
import { BasicButton } from 'Components/Buttons';
import { icons } from 'assets';
import { onlyNumbers } from 'utils/caseFormatter';

const Component: React.FC<Props> = ({ text, captchaId, isFail }) => {
  const [captcha, setCaptcha] = useState('');
  const dataFetchedRef = useRef(false);
  const fetchCaptcha = async () => {
    const res = await getCaptcha();
    setCaptcha(res?.picture);
    captchaId?.set(res?.id);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchCaptcha();
  }, []);

  useEffect(() => {
    if (isFail?.val) {
      fetchCaptcha();
      isFail?.set(false);
    }
  }, [isFail?.val]);

  const limitInput = (val: string, setVal: (v: string) => void, limit: number) => {
    if (val.length <= limit) setVal(val);
  };

  return (
    <Box
      p={2}
      display='flex'
      flexDirection='column'
      gap={3}
      minWidth={300}
      maxWidth={400}
      bgcolor={'#F5F5F5'}
      className='border border-[#dedede] mb-7 mt-6'
    >
      {captcha === '' ? (
        <div className='justify-center'>failed to get captcha</div>
      ) : (
        <img src={captcha} alt='' id={captchaId?.val} className='w-full' />
      )}
      <Box display='flex' gap={2}>
        <BasicTextField
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: '5px' }}
          size='small'
          placeholder='Input captcha'
          value={text?.val}
          onInput={onlyNumbers}
          onChange={(e) => {
            e.preventDefault();
            limitInput(e.target.value, text?.set, 5);
          }}
        />
        <BasicButton onClick={() => fetchCaptcha()} variant='text'>
          <img src={icons.refreshIcon} alt='' />
        </BasicButton>
      </Box>
    </Box>
  );
};
export default Component;
