import provider, { IProvider } from 'provider';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/store';
import { UtilsType, drawerContentProp } from 'redux/types/utilsTypes';
import MethodProvider from 'provider/methods';
import { endpoints } from 'api/endpoints';
import axios from 'axios';
import { errorGlobal } from './errorAction';
import { toast } from 'react-toastify';
import { setRealCookie } from 'utils/cookie';

export const setToolbar = (title: string) => (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  dispatch({ type: UtilsType.SET_TOOLBAR, payload: title });
};

export const setDrawerContent = (content: drawerContentProp) => async (dispatch) => {
  await dispatch({
    type: UtilsType.CHANGE_DRAWER_CONTENT,
    payload: content,
  });
};

export const getCaptcha = async () => {
  const captchaConfig = { height: 50, width: 180 };
  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.captcha,
      data: captchaConfig,
    };
    const response = await provider(objProvider);
    if (response.data?.code === 202) return response.data.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      errorGlobal(err);
      toast.error(err.response.data?.message);
    } else console.log(err);
  }
};

// use Referer Control in extensions chrome and custom referer to http://qoinhub.id
export const generateXSRFToken = async () => {
  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: 'v2/tok',
    };
    const response = await provider(objProvider);
    if (!response) throw new Error('Network Error');
    if (response.data) {
      setRealCookie('xsrf-token', response?.data?.data?._tok, 0.05);
    } else throw new Error('Something went wrong');
  } catch (err) {
    console.log(`Error: ${String(err)}`);
  }
};
