import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { refreshToken } from 'redux/actions/authActions';
import { JWTKeycloak } from 'redux/types/authTypes';
import { getCookie, getRealCookie } from 'utils/cookie';
import { keycloakLogout, refreshKeycoakToken } from 'utils/kcAuth';

const token = getCookie('token-web-admin');

const headers = {
  'Content-type': 'application/json',
  'Accept': 'application/json',
};

if (token) {
  Object.assign(headers, { Authorization: `Bearer ${token}` });
}

const instances = axios.create({
  timeout: 1000000,
  headers,
});

// its to intercept the request before being submitted to API
function onFulFilledRequest(conf: AxiosRequestConfig) {
  // if the token located on cookies already removed, forced to logout on API hit
  if (getRealCookie('token-web-admin') === undefined) keycloakLogout();
  return conf;
}

function onRejectedRequest(err: any): Promise<any> {
  return Promise.reject(err);
}

function onFulFilledResponse(res: any): Promise<any> {
  return Promise.resolve(res);
}

function onRejectedResponse(err: AxiosError) {
  const { response } = err;

  const kcToken = getRealCookie('token-web-admin');

  if (response?.status === 401 && response?.config?.headers?.Authorization) {
    try {
      if (kcToken) {
        const decoded: JWTKeycloak = jwtDecode(token);
        // temporary if condition, until the BE services are all capable to read JWT keycloak
        if (decoded.exp * 1000 < Date.now()) refreshKeycoakToken();
      } else refreshToken();
    } catch (error) {
      throw new Error(error);
    }
    return Promise.reject(err);
  }

  return Promise.reject(err);
}

instances.interceptors.request.use(onFulFilledRequest, onRejectedRequest);
instances.interceptors.response.use(onFulFilledResponse, onRejectedResponse);

export default instances;
