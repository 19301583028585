import update from 'immutability-helper';
import { IDashboard, IDashboardState, DashboardTypes } from 'redux/types/dashboardTypes';

const initialState = {
  loading: false,
  totalIncome: [],
  totalTransaction: [],
  totalTop10Freq: [],
  totalTop10Nominal: [],
  totalTransactionPChannel: [],
  totalTransactionPMethode: [],
  totalTransactionAmount: [],
  totalTransactionFreq: [],
  error: null,
};

export default (state: IDashboardState = initialState, { type, payload }: IDashboard) => {
  switch (type) {
    case DashboardTypes.GET_DASHBOARD_PENDING:
      return update(state, { loading: { $set: true } });
    case DashboardTypes.GET_DASHBOARD_ERROR:
      return update(state, {
        loading: { $set: false },
        totalIncome: { $set: [] },
        totalTransaction: { $set: [] },
        totalTop10Freq: { $set: [] },
        totalTop10Nominal: { $set: [] },
        totalTransactionPChannel: { $set: [] },
        totalTransactionPMethode: { $set: [] },
        totalTransactionAmount: { $set: [] },
        totalTransactionFreq: { $set: [] },
        error: { $set: payload.error },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_INCOME:
      return update(state, {
        loading: { $set: false },
        totalIncome: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION:
      return update(state, {
        loading: { $set: false },
        totalTransaction: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TOP10_FREQ:
      return update(state, {
        loading: { $set: false },
        totalTop10Freq: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TOP10_NOMINAL:
      return update(state, {
        loading: { $set: false },
        totalTop10Nominal: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_AMOUNT:
      return update(state, {
        loading: { $set: false },
        totalTransactionAmount: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_FREQ:
      return update(state, {
        loading: { $set: false },
        totalTransactionFreq: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_PCHANNEL:
      return update(state, {
        loading: { $set: false },
        totalTransactionPChannel: { $set: payload },
      });
    case DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_PMETHODE:
      return update(state, {
        loading: { $set: false },
        totalTransactionPMethode: { $set: payload },
      });

    default:
      return state;
  }
};
