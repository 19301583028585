import React from 'react';
import { Button, ButtonProps, ThemeProvider } from '@mui/material';
import { btnTheme } from 'configs/theme';
import ExportIcon from 'assets/icons/export.svg';
import { BasicPopover } from 'Components/Popover';

type Content = { val: boolean; onClick?: () => void };
type Props = ButtonProps & { excel?: Content; pdf?: Content; csv?: Content };
const Component: React.FC<Props> = (props) => {
  const { excel, pdf, csv } = props;
  const anchorRef = React.useRef(null);
  const [exportOpen, setExportOpen] = React.useState(false);

  const handleToggle = () => {
    setExportOpen(!exportOpen);
  };

  return (
    <ThemeProvider theme={btnTheme}>
      <Button
        variant='outlined'
        className='gap-2 cursor-pointer'
        onClick={handleToggle}
        ref={anchorRef}
        color='success'
        id='btn-export'
        {...props}
      >
        <img src={ExportIcon} alt='export' />
        <span>export</span>
      </Button>

      <BasicPopover
        open={exportOpen}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setExportOpen(false)}
      >
        {excel?.val && (
          <Button id='btn-export-excel' onClick={excel?.onClick}>
            Excel
          </Button>
        )}
        {pdf?.val && (
          <Button id='btn-export-pdf' onClick={pdf?.onClick}>
            PDF
          </Button>
        )}
        {csv?.val && (
          <Button id='btn-export-csv' onClick={csv?.onClick}>
            CSV
          </Button>
        )}
      </BasicPopover>
    </ThemeProvider>
  );
};

export default Component;
