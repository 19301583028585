/* eslint-disable import/no-anonymous-default-export */
import { NotificationType, NotificationAction, INotificationState } from 'redux/types/notificationTypes';

const initialState = {
  isLoading: false,
  isDetail: false,
  isNotif: false,
  detail: null,
  category: [],
  data: { message_list: [], message_top_list: [] },
  error: null,
};

export default (state: INotificationState = initialState, { type, payload }: NotificationAction) => {
  switch (type) {
    case NotificationType.GET_LIST_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case NotificationType.GET_LIST_SUCCES:
      return {
        ...state,
        data: {
          ...state.data,
          message_list: state.data.message_list.concat(payload.data.message_list),
        },
        isLoading: false,
      };
    case NotificationType.REFRESH_LIST_SUCCES:
      return {
        ...state,
        data: {
          ...state.data,
          message_list: payload.data.message_list,
        },
        isLoading: false,
      };
    case NotificationType.GET_LIST_TOP_SUCCES:
      return {
        ...state,
        data: {
          ...state.data,
          message_top_list: payload.data.message_list,
        },
        isLoading: false,
      };
    case NotificationType.GET_LIST_ERROR:
      return { ...state, ...payload, error: null, isLoading: false };

    case NotificationType.SET_DETAIL:
      return { ...state, ...payload, error: null, isLoading: false };
    case NotificationType.SET_ISDETAIL:
      return { ...state, ...payload, error: null, isLoading: false };
    case NotificationType.GET_LIST_CATEGORY:
      return { ...state, ...payload, error: null, isLoading: false };

    default:
      return state;
  }
};
