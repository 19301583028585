import MethodProvider from 'provider/methods';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/store';
import {
  AuthTypes,
  PLogin,
  JWTdata,
  PForgot,
  PForgotValid,
  PEmailValid,
  PEditProfile,
  PEditPassword,
} from 'redux/types/authTypes';
import jwtDecode from 'jwt-decode';
import { endpoints } from 'api/endpoints';
import { toast } from 'react-toastify';
import { getCookie, removeCookie, removeRealCookie, sessionClear, setCookie } from 'utils/cookie';
import { routePath } from 'utils/exception';
import provider, { IProvider } from 'provider';
import axios, { AxiosError } from 'axios';
import { errorGlobal } from './errorAction';
import { ActivityData, addAuditrail } from 'hooks/useActivityBrowser';
import { captchaType } from 'redux/types/utilsTypes';
import { captchaForm } from 'Components/captcha';

export const loginAccount = (body: PLogin & captchaType, callback?: (v: string) => void) => async (dispatch) => {
  const { email, password, browserId, ipAddress, latitude, longitude } = body;
  const loginData = new FormData();
  loginData.append('Email', email);
  loginData.append('Password', password);
  loginData.append('Source', 'web');
  addAuditrail(loginData, { browserId, ipAddress, latitude, longitude });
  captchaForm(loginData, body?.Id, body?.VerifyValue);

  dispatch({ type: AuthTypes.USER_LOGIN_PENDING });
  callback('');
  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.login,
      data: loginData,
    };
    const response = await provider(objProvider);
    if (response.data?.status === 'success') {
      if (response.data?.data?.ClientId === 2) {
        setCookie('token-web-admin', response.data.data.Token);
        dispatch({
          type: AuthTypes.USER_LOGIN_SUCCESS,
          payload: response.data.data,
        });
        let jwt: JWTdata = jwtDecode(response.data.data.Token);
        setCookie('permission-web-admin', JSON.stringify(jwt.Permission));
        window.location.replace(routePath);
      } else {
        callback('User Invalid');
      }
    }
  } catch (err) {
    authError(err, callback);
  }
};

export const resetPassword = async (body: PForgotValid, callback?: (v: string) => void) => {
  const { password, confirm, key, browserId, ipAddress, latitude, longitude } = body;
  const resetData = new FormData();
  resetData.append('NewPassword', password);
  resetData.append('NewPasswordConfirmation', confirm);
  resetData.append('ForgotKey', key);
  addAuditrail(resetData, { browserId, ipAddress, latitude, longitude });

  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.validateForgotPassword,
      data: resetData,
    };
    const response = await provider(objProvider);
    if (response.data?.status === 'success') {
      toast.success(response.data?.data?.message);
      window.location.replace(routePath);
    }
  } catch (err) {
    authError(err, callback);
  }
};

export const forgotPassword = async (body: PForgot & captchaType, callback?: (v: string) => void) => {
  const { email, browserId, ipAddress, latitude, longitude } = body;
  const formdata = new FormData();
  formdata.append('Email', email);
  formdata.append('Source', 'admin');
  addAuditrail(formdata, { browserId, ipAddress, latitude, longitude });
  captchaForm(formdata, body?.Id, body?.VerifyValue);

  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.initialForgotPassword,
      data: formdata,
    };
    const response = await provider(objProvider);
    if (response.data?.code === 201 || response.data?.status === 'success') {
      window.location.replace(`${routePath}email-sent`);
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      errorGlobal(err);
      toast.error(err.response.data?.message);
      const errorCode = err.response.data?.code;

      let message = '';
      if (errorCode === 400) {
        let content = Object.values(err.response.data?.data)[0];
        message = content[0];
      } else if (errorCode === 404) message = err.response.data?.message;
      else message = err.message;

      callback(message);
    } else console.log(err);
  }
};

export const getProfile = () => async (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => {
  dispatch({ type: AuthTypes.GET_PROFILE_PENDING });
  try {
    const objProvider: IProvider = {
      method: MethodProvider.GET,
      path: endpoints.profile,
    };
    const response = await provider(objProvider);
    if (response.data?.code === 200) {
      dispatch({
        type: AuthTypes.GET_PROFILE_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      errorGlobal(err);
      toast.error(err.response.data?.message);
      dispatch({
        type: AuthTypes.GET_PROFILE_ERROR,
        payload: { error: err.response.data },
      });
    } else console.log(err);
  }
};

export const validateEmail = async (body: PEmailValid) => {
  const { key, browserId, ipAddress, latitude, longitude } = body;
  const validateData = new FormData();
  validateData.append('EmailKey', key);
  addAuditrail(validateData, { browserId, ipAddress, latitude, longitude });

  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.validateEmail,
      data: validateData,
    };
    const response = await provider(objProvider);
    if (response.data?.status === 'success') {
      return response.data?.data;
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      toast.error(err.response.data?.message);
      authError(err);
      window.location.replace(routePath);
    } else console.log(err);
  }
  // const response = await apiPost(endpoints.validateEmail, body);

  // if (response?.data?.status === 'success') return response;
  // return false;
};

export const refreshToken = async () => {
  const token = getCookie('token-web-admin');
  const refreshForm = new FormData();
  refreshForm.append('Token', token);

  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.refresh,
      data: refreshForm,
    };
    const response = await provider(objProvider);
    if (response.data?.code === 201) {
      setCookie('token-web-admin', response.data.data?.Token);
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      errorGlobal(err);
      toast.error(err.response.data?.message);
      if (err.response.data.code === 401 || err.response.data?.status.includes('unauthorized')) {
        sessionClear();
        window.location.replace(`${routePath}login`);
      } else {
        console.log(err.response.data['message'] || err.message);
      }
    } else console.log(err);
  }
};

export const logout = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  removeRealCookie('token-web-admin');
  removeRealCookie('refresh_token');
  removeCookie('permission-web-admin');
  sessionClear();
  dispatch({ type: AuthTypes.USER_LOGOUT });
};

function authError(err: any, callback?: (v: string) => void) {
  const error = err as AxiosError;
  if (error.response?.data?.data) {
    const message = Object.values(error.response.data?.data);
    callback(message[0][0]);
    toast.error(message[0][0]);
  } else {
    callback(error.response.data?.message);
    toast.error(error.response.data?.message);
  }

  errorGlobal(error);
}

export const editProfile = async (data: PEditProfile & ActivityData, callback?: (v: boolean) => void) => {
  try {
    const { phone, username } = data;
    const formProfile = new FormData();
    if (username) formProfile.append('Username', username);
    if (phone) formProfile.append('Phone', phone);
    addAuditrail(formProfile, data);

    const objProvider: IProvider = {
      method: MethodProvider.PUT,
      path: endpoints.profile,
      data: formProfile,
    };
    const response = await provider(objProvider);
    // const response = await apiPut(endpoints.profile, formProfile, false);

    if (!response) toast.error('Network Error!');
    if (response.data?.code === 200) {
      // toast.success(response.data?.message);
      callback(true);
      return response;
    }
  } catch (error) {
    isAxiosHandle(error);
  }
};

export const editPhotoProfile = async (photo: File, callback?: (v: boolean) => void) => {
  const photoData = new FormData();
  photoData.append('Photo', photo);
  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.profilePhoto,
      data: photoData,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('Network Error');
    if (response.data?.code === 200) {
      // toast.success(response.data?.message ?? 'Upload Success')
      callback(true);
    }
  } catch (error) {
    isAxiosHandle(error);
  }
};

export const editPassword = async (data: PEditPassword & ActivityData, callback?: (v: boolean) => void) => {
  try {
    const passForm = new FormData();
    passForm.append('OldPassword', data.OldPassword);
    passForm.append('NewPassword', data.NewPassword);
    passForm.append('PasswordConfirmation', data.PasswordConfirmation);
    addAuditrail(passForm, data);

    const objProvider: IProvider = {
      method: MethodProvider.PUT,
      path: endpoints.changePassword,
      data: passForm,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('Network Error');
    if (response.data?.code === 200) {
      callback(true);
    }
  } catch (error) {
    isAxiosHandle(error);
  }
};

const isAxiosHandle = (error) => {
  if (axios.isAxiosError(error)) {
    errorGlobal(error);
    toast.error(error.response.data?.message);
  } else console.log(error);
};
