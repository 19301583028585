import React from 'react';
import { CircularProgress, CircularProgressProps, ThemeProvider } from '@mui/material';
import { colorTheme } from 'configs/theme';

type Props = {} & CircularProgressProps;
const Component: React.FC<Props> = (props) => {
  return (
    <ThemeProvider theme={colorTheme}>
      <CircularProgress color='success' variant='indeterminate' {...props} />
    </ThemeProvider>
  );
};
export default Component;
