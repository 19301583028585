import { AxiosError } from 'axios';

export enum DashboardTypes {
  GET_DASHBOARD_TOTAL_INCOME = 'GET_DASHBOARD_TOTAL_INCOME',
  GET_DASHBOARD_TOTAL_TRANSACTION = 'GET_DASHBOARD_TOTAL_TRANSACTION',
  GET_DASHBOARD_TOTAL_TOP10_FREQ = 'GET_DASHBOARD_TOTAL_TOP10_FREQ',
  GET_DASHBOARD_TOTAL_TOP10_NOMINAL = 'GET_DASHBOARD_TOTAL_TOP10_NOMINAL',
  GET_DASHBOARD_TOTAL_TRANSACTION_PCHANNEL = 'GET_DASHBOARD_TOTAL_TRANSACTION_PCHANNEL',
  GET_DASHBOARD_TOTAL_TRANSACTION_PMETHODE = 'GET_DASHBOARD_TOTAL_TRANSACTION_PMETHODE',
  GET_DASHBOARD_TOTAL_TRANSACTION_AMOUNT = 'GET_DASHBOARD_TOTAL_TRANSACTION_AMOUNT',
  GET_DASHBOARD_TOTAL_TRANSACTION_FREQ = 'GET_DASHBOARD_TOTAL_TRANSACTION_FREQ',
  GET_DASHBOARD_PENDING = 'GET_DASHBOARD_PENDING',
  GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR',
}

export interface IDashboardState {
  loading?: boolean;
  totalIncome?: any[];
  totalTransaction?: any[];
  totalTop10Freq?: any[];
  totalTop10Nominal?: any[];
  totalTransactionPChannel?: any[];
  totalTransactionPMethode?: any[];
  totalTransactionAmount?: any[];
  totalTransactionFreq?: any[];
  error?: AxiosError;
}

export interface IParamDashboard {
  date?: string;
  merchantNo?: number;
  status?: number;
  type: 'admin' | 'merchant';
}

interface IDashboardPending {
  type: DashboardTypes.GET_DASHBOARD_PENDING;
  payload: null;
}

interface IDashboardError {
  type: DashboardTypes.GET_DASHBOARD_ERROR;
  payload: { error: AxiosError };
}
interface IDashboardIncome {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_INCOME;
  payload: [];
}
interface IDashboardTransaction {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION;
  payload: [];
}
interface IDashboardTop10Freq {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TOP10_FREQ;
  payload: [];
}
interface IDashboardTop10Nominal {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TOP10_NOMINAL;
  payload: [];
}
interface IDashboardPChannel {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_PCHANNEL;
  payload: [];
}
interface IDashboardPMethode {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_PMETHODE;
  payload: [];
}
interface IDashboardTotalAmount {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_AMOUNT;
  payload: [];
}
interface IDashboardTotalFreq {
  type: DashboardTypes.GET_DASHBOARD_TOTAL_TRANSACTION_FREQ;
  payload: [];
}

export type IDashboard =
  | IDashboardPending
  | IDashboardError
  | IDashboardIncome
  | IDashboardTransaction
  | IDashboardTop10Nominal
  | IDashboardTop10Freq
  | IDashboardPChannel
  | IDashboardPMethode
  | IDashboardTotalAmount
  | IDashboardTotalFreq;
