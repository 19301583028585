export enum UtilsType {
  SET_TOOLBAR = 'SET_TOOLBAR',
  CHANGE_DRAWER_CONTENT = 'CHANGE_DRAWER_CONTENT',
  SWITCH_LANG = 'SWITCH_LANG',
}

export type captchaType = { Id: string; VerifyValue: string };
export type drawerContentProp = 'user' | 'profile' | 'password';
export interface IUtilsState {
  toolbar: string;
  content: string;
  lang?: string;
}

interface IToolbarChange {
  type: UtilsType.SET_TOOLBAR;
  payload: string;
}

interface SetDrawerContent {
  type: UtilsType.CHANGE_DRAWER_CONTENT;
  payload: drawerContentProp;
}

interface ILangChange {
  type: UtilsType.SWITCH_LANG;
  payload: 'id' | 'en';
}

export type IUtils = IToolbarChange | SetDrawerContent | ILangChange;
