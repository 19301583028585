import moment from 'moment';

export const toSnakeCase = (str: string) =>
  str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toTextCase = (str: string) =>
  str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);

export const formatDate = (date, format = 'DD-MM-YYYY HH:mm:ss', utc = false) => {
  const theDate = utc ? moment(date).utc().format(format) : moment(date).format(format);
  return theDate !== 'Invalid date' ? theDate : null;
};

export const onlyNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};
export const isEmpty = (v: string) => {
  if (v === '') return '-';
  return v;
};

export function limitInput(inputVal: string, setValue: (v: string) => void, limit: number, regex?: RegExp) {
  if (regex) {
    if (regex.test(inputVal) && inputVal.length <= limit) {
      setValue(inputVal);
    }
  } else {
    if (inputVal.length <= limit) setValue(inputVal);
  }
}
