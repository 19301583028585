/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { IReport, IReportState, ReportTypes } from 'redux/types/reportTypes';

const initialState: IReportState = {
  loading: false,
  loadingChild: false,
  transaction: [],
  transactionDetail: {},
  pagination: {},
  error: null,
  paramsTransaction: [],
  paramsWithdrawal: [],
  masterMerchantProd: [],
  withdrawalList: [],
  withDrawalDetail: {},
  mutation: null,
};

export default (state = initialState, { type, payload }: IReport) => {
  switch (type) {
    case ReportTypes.GET_REPORT_PENDING:
      return update(state, { loading: { $set: true } });
    case ReportTypes.GET_REPORT_PENDING_CHILD:
      return update(state, { loadingChild: { $set: true } });
    case ReportTypes.GET_REPORT_ERROR:
      return update(state, {
        loading: { $set: false },
        loadingChild: { $set: false },
        error: { $set: payload.error },
        donation: { $set: [] },
        donationTransfer: { $set: [] },
        insurance: { $set: [] },
        insuranceTransfer: { $set: [] },
        transaction: { $set: [] },
        settlement: { $set: [] },
        withdrawal: { $set: [] },
        journal: { $set: [] },
        journalSummary: { $set: [] },
        pagination: { $set: {} },
        // paramsTransaction: { $set: {} },
        // masterMerchantProd: { $set: {} },
      });
    case ReportTypes.GET_REPORT_TRANSACTION_SUCCESS:
      return update(state, {
        loading: { $set: false },
        transaction: { $set: payload.transactions },
        pagination: { $set: payload.pagination },
      });

    case ReportTypes.GET_REPORT_TRANSACTION_DETAIL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        transactionDetail: { $set: payload },
      });
    case ReportTypes.GET_REPORT_WITHDRAWAL_DETAIL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        withDrawalDetail: { $set: payload },
      });
    case ReportTypes.GET_REPORT_WITHDRAWALS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        withdrawalList: { $set: payload.withdrawal_lists },
        pagination: { $set: payload.pagination },
      });
    case ReportTypes.GET_PARAMS_WITHDRAWALS:
      return update(state, {
        loading: { $set: false },
        paramsWithdrawal: { $set: payload },
      });
    case ReportTypes.GET_PARAMS_TRANSACTION:
      return update(state, {
        loading: { $set: false },
        paramsTransaction: { $set: payload },
      });
    case ReportTypes.GET_MASTER_MERCHANT_PROD:
      return update(state, {
        loading: { $set: false },
        masterMerchantProd: { $set: payload },
      });
    case ReportTypes.EXPORT_PENDING:
      return update(state, { loading: { $set: true } });
    case ReportTypes.EXPORT_ERROR:
      return update(state, { loading: { $set: false }, error: { $set: payload.error } });
    case ReportTypes.EXPORT_TRANSACTION_SUCCESS:
      return update(state, {
        loading: { $set: false },
        transaction: { $set: payload },
      });
    case ReportTypes.EXPORT_WITHDRAWAL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        // withdrawalList: { $set: payload },
      });
    case ReportTypes.GET_MUTATION_START:
      return update(state, { loading: { $set: true } });
    case ReportTypes.GET_MUTATION_LIST:
      return update(state, { loading: { $set: false }, mutation: { $set: payload } });
    case ReportTypes.GET_MUTATION_ERROR:
      return initialState;
    default:
      return state;
  }
};
